import { gql, useQuery } from "@apollo/client";

import MarkdownText from "../../components/markdown-text/markdown-text.component";
import LoadingPage from "../../components/loading-page/loading-page.component";

import "./legal-disclaimer.styles.scss";

const LEGAL = gql`
  query GetLegalDisclaimer {
    legalDisclaimer {
      data {
        attributes {
          Page
        }
      }
    }
  }
`;

const LegalDisclaimer = () => {
  const { loading, error, data } = useQuery(LEGAL);

  if (loading) return <LoadingPage />;
  if (error) return <></>;

  const pageText = data.legalDisclaimer.data.attributes.Page;

  return (
    <main className="page-legal page-wrapper page-wrapper__padding">
      <MarkdownText content={pageText} />
    </main>
  );
};

export default LegalDisclaimer;
