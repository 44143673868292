import { gql, useQuery } from "@apollo/client";

import LoadingPage from "../loading-page/loading-page.component";
import ErrorPage from "../error-page/error-page.component";
import HomeHubInner from "../home-hub-inner/home-hub-inner.component";
import IntegrationPopUp from "../integration-popup/integration-popup.component";

import { ReactComponent as DotCluster } from "../../img/background-decor/home/home-hub-dots.svg";

import "./home-hub.styles.scss";

const INTEGRATIONS = gql`
  query GetIntegrations {
    integrationsHubs(
      pagination: { limit: 500 }
      sort: "onDisplayOrder"
      filters: {
        categories: { ihCategory: { in: ["Casino", "Services", "Payments"] } }
      }
    ) {
      data {
        id
        attributes {
          logo {
            data {
              id
              attributes {
                url
              }
            }
          }
          Title
          popupDetails
          ExtraDetails
          ExtraDetails_Type
          url
          types {
            data {
              id
              attributes {
                ihType
              }
            }
          }
          video {
            data {
              attributes {
                url
              }
            }
          }
          categories {
            data {
              id
              attributes {
                ihCategory
              }
            }
          }
        }
      }
    }
  }
`;

const HomeHub = () => {
  const { loading, error, data } = useQuery(INTEGRATIONS);

  if (loading) return <LoadingPage />;
  if (error) {
    console.error('Error found in Home-hub component.', error);
  }

  const hubData = data.integrationsHubs ? data.integrationsHubs.data : null;

  return (
    <section className="home-hub">
      <div className="home-hub__inner-container page-wrapper">
        {hubData ? <HomeHubInner hubData={hubData} /> : <div></div>}
        {hubData ? <IntegrationPopUp data={hubData} /> : <div></div>}
        {error && <ErrorPage />}
        <DotCluster className="home-hub__dots" />
      </div>
    </section>
  );
};

export default HomeHub;
