import Contacts from "../contacts/contacts.component";
import Social from "../social/social.component";

import "./footer-social.styles.scss";

const FooterSocial = () => {
  return (
    <section className="social-row">
      <Contacts />
      <Social />
    </section>
  );
};

export default FooterSocial;
