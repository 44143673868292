import { useRef, useEffect, useState } from "react";

import "./slider.styles.scss";

import { ReactComponent as ChevronRight } from "../../img/carousel/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "../../img/carousel/chevron-left.svg";

const Slider = ({
  sliderConfig,
  item,
  dots,
  displayDots,
  dotsClass,
  fullCardView,
}) => {
  const buttonLeft = useRef(null);
  const buttonRight = useRef(null);
  const slider = useRef(null);
  const dotsRef = useRef(null);

  const [sliderPositionIndex, setSliderPositionIndex] = useState(0);
  const [btnRightHide, setBtnRightHide] = useState(false);
  const [btnLeftHide, setBtnLeftHide] = useState(true);

  const onLeftClick = () => {
    let sliderIndex = sliderPositionIndex - 1;
    const styleIndex = Math.max(sliderIndex, 0);
    slider.current.style.setProperty("--slider-index", styleIndex);

    setBtnRightHide(false);

    styleIndex > 0 ? setBtnLeftHide(false) : setBtnLeftHide(true);

    if (displayDots) {
      const dotsArr = [...dotsRef.current.children];
      dotsArr.forEach((dot, i) => {
        i === sliderIndex
          ? dot.classList.add(`${dotsClass}-active`)
          : dot.classList.remove(`${dotsClass}-active`);
      });
    }
    setSliderPositionIndex(sliderIndex);
  };

  const onRightClick = () => {
    const sliderIndex = sliderPositionIndex + 1;

    const sliderElCount = parseInt(
      getComputedStyle(slider.current).getPropertyValue("--items-per-screen")
    );
    const maxSliderBlocks = Math.floor(
      slider.current.childElementCount / sliderElCount
    );

    const fullRowIndicator =
      slider.current.childElementCount % sliderElCount === 0 ? true : false;

    const styleIndex = Math.min(sliderIndex, maxSliderBlocks);
    slider.current.style.setProperty("--slider-index", styleIndex);

    setBtnLeftHide(false);

    if (fullRowIndicator && styleIndex < maxSliderBlocks - 1) {
      setBtnRightHide(false);
    } else if (!fullRowIndicator && styleIndex < maxSliderBlocks) {
      setBtnRightHide(false);
    } else {
      setBtnRightHide(true);
    }

    if (displayDots) {
      const dotsArr = [...dotsRef.current.children];
      dotsArr.forEach((dot, i) => {
        i === styleIndex
          ? dot.classList.add(`${dotsClass}-active`)
          : dot.classList.remove(`${dotsClass}-active`);
      });
    }
    setSliderPositionIndex(sliderIndex);
  };

  // this hides right button on load, if there are not enough items to swipe right
  useEffect(() => {
    const sliderChildren = slider.current.childElementCount;
    const sliderElCount = parseInt(
      getComputedStyle(slider.current).getPropertyValue("--items-per-screen")
    );

    sliderChildren - 1 < sliderElCount
      ? setBtnRightHide(true)
      : setBtnRightHide(false);
  }, []);

  let dotsArray = [];
  for (let i = 0; i < dots; i++) {
    dotsArray.push(i);
  }

  // Mobile touch slider movement
  const [touchPosition, setTouchPosition] = useState(null);
  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      buttonRight.current.click();
    }

    if (diff < -5) {
      buttonLeft.current.click();
    }

    setTouchPosition(null);
  };

  return (
    <>
      <button
        ref={buttonLeft}
        className={`handle left-handle ${
          btnLeftHide ? "slider-button-hidden" : ""
        } `}
        onClick={onLeftClick}
      >
        <ChevronLeft />
      </button>
      <div className={` ${fullCardView ? "slider-overflow" : ""}`}>
        <div
          ref={slider}
          className={`${sliderConfig}`}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {item}
        </div>
      </div>
      <button
        ref={buttonRight}
        className={`handle right-handle ${
          btnRightHide ? "slider-button-hidden" : ""
        }`}
        onClick={onRightClick}
      >
        <ChevronRight />
      </button>

      {displayDots ? (
        <div className="dot-box" ref={dotsRef}>
          {dotsArray.map((dot, i) => (
            <div
              className={`${dotsClass} ${i === 0 ? `${dotsClass}-active` : ""}`}
              key={dot}
              id={dot}
            ></div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Slider;
