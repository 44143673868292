import { useParams, Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import SEO from "../../components/seo/seo.component";

import NewsSlider from "../../components/news-slider/news-slider.component";
import MarkdownText from "../../components/markdown-text/markdown-text.component";

import "./article.styles.scss";

import LabelIcon from "../../img/news-article/label-icon.png";
import LinkedInIcon from "../../img/news-article/share-linkedin.png";

const BLOG = gql`
  query GetBlog($slug: String) {
    blog: blogs(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          Title
          Publication_Date
          previewText
          mainImg {
            data {
              id
              attributes {
                url
              }
            }
          }
          mainImgText
          article
          category {
            data {
              id
              attributes {
                Name
                slug
              }
            }
          }
          blog_labels {
            data {
              id
              attributes {
                Label
                slug
              }
            }
          }
        }
      }
    }
    blogs: blogs(
      filters: { slug: { ne: $slug } }
      sort: "Publication_Date:desc"
      pagination: { limit: 20 }
    ) {
      data {
        id
        attributes {
          previewImg {
            data {
              id
              attributes {
                url
              }
            }
          }
          previewImgText
          previewText
          Title
          slug
          Publication_Date
          category {
            data {
              id
              attributes {
                Name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

const Article = () => {
  const { slug } = useParams();
  const { loading, error, data } = useQuery(BLOG, {
    variables: { slug: slug },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const {
    id,
    attributes: {
      article,
      Title,
      category,
      Publication_Date,
      mainImg,
      previewText,
      mainImgText,
      blog_labels,
    },
  } = data.blog.data[0];

  const similarNews = data.blogs.data;
  const siteURL = window.location.href;

  const LinkedInPopOpener = () =>
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${siteURL}`,
      "",
      "width=900, height=750"
    );

  return (
    <>
      <SEO
        title={Title ? Title : null}
        description={previewText ? previewText : null}
        type="article"
        image={
          mainImg.data
            ? `${process.env.REACT_APP_CMS_MEDIA}${mainImg.data.attributes.url}`
            : null
        }
      />
      <div className="blog-post" key={id}>
        <div className="blog-post__inner page-wrapper">
          <main className="  page-wrapper__padding" key={id}>
            <div className="blog-post__header-box">
              <Link
                to={`/category/${category.data.attributes.slug}`}
                replace
                className="blog-post__category"
              >
                {category.data.attributes.Name}
              </Link>
            </div>

            <div className="blog-post__hero-container">
              {mainImg.data ? (
                <div className="blog-post__img-box">
                  <img
                    src={`${process.env.REACT_APP_CMS_MEDIA}${mainImg.data.attributes.url}`}
                    alt={mainImgText}
                    className="blog-post__img"
                  />
                </div>
              ) : null}
              <div className="blog-post__overlay">
                <h1 className="heading-2">{Title}</h1>
              </div>
            </div>

            <div className="blog-post__details-box">
              <p className="blog-post__date">{Publication_Date}</p>
            </div>

            <MarkdownText
              className="blog-post__article"
              content={article}
            ></MarkdownText>

            {blog_labels.data.length > 0 ? (
              <div className="blog-post__labels-box">
                <div className="blog-post__label-icon-box">
                  <img
                    src={LabelIcon}
                    alt="Label"
                    className="blog-post__label-icon"
                  />
                </div>
                <ul className="blog-post__labels">
                  {blog_labels.data.map(({ id, attributes }) => (
                    <li key={id} className="blog-post__label">
                      <Link
                        to={`/label/${attributes.slug}`}
                        replace
                        className="blog-post__label-link"
                      >
                        {attributes.Label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}

            <div className="blog-post__share-box">
              <p className="blog-post__share-title">Share this:</p>
              <ul className="blog-post__share-links">
                <li className="blog-post__share-link">
                  <img
                    src={LinkedInIcon}
                    alt="Linked In"
                    className="blog-post__share-icon"
                    onClick={LinkedInPopOpener}
                  />
                </li>
              </ul>
            </div>
          </main>

          {similarNews && (
            <>
              <h3 className="heading-3 blog-post__other-header">
                In other news:
              </h3>
              <NewsSlider dataBlock={similarNews} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Article;
