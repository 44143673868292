import MarkdownText from "../markdown-text/markdown-text.component";

import "./integration-popup.styles.scss";

const IntegrationPopUp = ({ data }) => {
  // Close popUp
  const closePopUp = (e) => {
    e.target.parentElement.parentElement.classList.add("hub-popUp__hidden");
  };

  //Close popUp on click outside box
  const closePopUpClickOutside = (e) => {
    if (e.target === e.target.closest(".hub-popUp"))
      e.target.classList.add("hub-popUp__hidden");
  };

  return data.map(({ id, attributes }) => (
    <div
      className="hub-popUp hub-popUp__hidden"
      id={attributes.Title.replaceAll(" ", "-")}
      key={id}
      onClick={closePopUpClickOutside}
    >
      <div className="hub-popUp__box">
        <div className="hub-popUp__top">
          <div className="hub-popUp__top-left">
            <img
              src={`${process.env.REACT_APP_CMS_MEDIA}${attributes.logo.data.attributes.url}`}
              alt={attributes.Title}
              className="hub-popUp__logo"
            />
            {attributes.types.data &&
              attributes.types.data.map((type, index) => {
                if(type.attributes.ihType) {
                  return (<p key={index} className="hub-popUp__type">{type.attributes.ihType}</p>)
                }

                return null
              })}
          </div>
          <div className="hub-popUp__top-right">
            <p className="hub-popUp__title">{attributes.Title}</p>
            <p className="hub-popUp__text">{attributes.popupDetails}</p>
            <a
              href={attributes.url}
              className="hub-popUp__url a-alt"
              target="_blank"
              rel="noreferrer"
            >
              {attributes.url}
            </a>
          </div>
        </div>
        {attributes.ExtraDetails_Type === "Empty" ? (
          <></>
        ) : attributes.ExtraDetails_Type === "Providers" ? (
          <div className="hub-popUp__bottom">
            <div className="hub-popUp__providers">
              <div className="hub-popUp__providers-title">
                {attributes.ExtraDetails_Type}
              </div>
              <MarkdownText
                className="hub-popUp__providers-list  hub-popUp__providers-columns"
                content={attributes.ExtraDetails}
              ></MarkdownText>
            </div>
          </div>
        ) : attributes.ExtraDetails_Type === "Video" ? (
          <div className="hub-popUp__video">
            <MarkdownText content={attributes.ExtraDetails}></MarkdownText>
            {attributes.video.data && (
              <video className="hub-popUp__video-video" controls>
                <source
                  src={`${process.env.REACT_APP_CMS_MEDIA}${attributes.video.data.attributes.url}`}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        ) : attributes.ExtraDetails_Type === "Services" ? (
          <MarkdownText
            className="hub-popUp__services markdown__no-list-padding"
            content={attributes.ExtraDetails}
          ></MarkdownText>
        ) : (
          <></>
        )}
        <div className="hub-popUp__close" onClick={closePopUp}></div>
      </div>
    </div>
  ));
};

export default IntegrationPopUp;
