import { Fragment } from "react";

import Slider from "../../components/slider/slider.component";
import CardIntegration from "../../components/card-integration/card-integration.component";

import "./integration-hub.styles.scss";

const IntegrationHub = ({ categories, integrations }) => {
  const filteredIntegrations = (arr, filterValue) => {
    const newArr = arr.filter((a) => {
      let trueCount = 0;
      a.attributes.categories.data.filter((category) => {
        return category.attributes.ihCategory.includes(filterValue)
          ? trueCount++
          : trueCount + 0;
      });
      return trueCount > 0 ? true : false;
    });
    return newArr;
  };

  return (
    <div className="integrations-hub__integrations-block">
      <ul className="integrations-hub__rows">
        {categories.map(({ ihCategory }) =>
          filteredIntegrations(integrations, ihCategory).length > 0 ? (
            <li className="integrations-hub__row" key={ihCategory}>
              <h4 className="heading-4 integrations-hub__category-title">
                {ihCategory}
              </h4>
              <div className="integrations-hub__slider-container">
                <Slider
                  sliderConfig="integrations-hub__slider"
                  fullCardView={true}
                  item={
                    <CardIntegration
                      data={filteredIntegrations(integrations, ihCategory)}
                      className="integrations-hub__card"
                    />
                  }
                />
              </div>
            </li>
          ) : (
            <Fragment key={ihCategory}></Fragment>
          )
        )}
      </ul>
    </div>
  );
};

export default IntegrationHub;
