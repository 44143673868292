import { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";

import LoadingPage from "../loading-page/loading-page.component";
import MarkdownText from "../markdown-text/markdown-text.component";

import "../../styles/heading.styles.scss";
import "./home-usp.styles.scss";

const USPITEMS = gql`
  query GetUSP {
    homeBlockUsps(sort: "onDisplayOrder") {
      data {
        id
        attributes {
          heading
          description
          icon {
            data {
              id
              attributes {
                url
              }
            }
          }
          iconALT
        }
      }
    }
  }
`;

const HomeUsp = ({ headers }) => {
  const { loading, error, data } = useQuery(USPITEMS);
  const [uspBlock, setUspBlock] = useState(null);

  useEffect(() => {
    if (data) {
      setUspBlock(data.homeBlockUsps.data);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        uspBlock && (
          <section className="section-usp ">
            <div className="section-usp__inner page-wrapper page-wrapper__padding">
              <h2 className="heading-2 txt-decor-letter-1">{headers.header}</h2>
              <div className="usp">
                <ul className="usp__list grid g-2-col">
                  {uspBlock.map(({ id, attributes }) => (
                    <li className="usp__item grid" key={id}>
                      <div className="usp__icon-box">
                        <img
                          src={`${process.env.REACT_APP_CMS_MEDIA}${attributes.icon.data.attributes.url}`}
                          alt={attributes.iconALT}
                          className="usp__icon"
                        />
                      </div>
                      <h5 className="heading-5">{attributes.heading}</h5>
                      <MarkdownText
                        className="usp__text"
                        content={attributes.description}
                      ></MarkdownText>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        )
      )}
    </>
  );
};

export default HomeUsp;
