import { HashLink as Link } from "react-router-hash-link";

import "./footer-sitemap.styles.scss";

const FooterSitemap = ({ siteMapData }) => {
  return (
    siteMapData && (
      <section className="sitemap">
        <ul className="sitemap__box">
          {siteMapData.map(({ id, blockName, links }) => (
            <li className="sitemap__block" key={id}>
              <p className="sitemap__block-title">{blockName}</p>
              <ul className="sitemap__block-items">
                {links.map(({ id, url, page }) => (
                  <li className="sitemap__block-item" key={id}>
                    <Link className="sitemap__url" to={url}>
                      {page}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </section>
    )
  );
};

export default FooterSitemap;
