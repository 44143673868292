import "./rec-card.styles.scss";

const RecCard = (data) => {
  const { text, url, icon, iconALT, jurisdiction, type } = data.data;

  return (
    <a className="rec-card" href={url ? url : "#!"} target="_blank">
      <div
        className={`rec-card__logo-box ${
          type === "licenses"
            ? "rec-card__logo-box-dark"
            : "rec-card__logo-box-light"
        }`}
      >
        <img
          src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
          alt={iconALT}
          className={`rec-card__logo ${
            type === "licenses" || type === "standarts"
              ? "rec-card__logo-50"
              : "rec-card__logo-76"
          }`}
        />
      </div>
      <div className="rec-card__text-box">
        <p className="rec-card__jurisdiction">
          {type === "jurisdictions" ? jurisdiction : ""}
        </p>
        <p className="rec-card__text">{text}</p>
      </div>
    </a>
  );
};

export default RecCard;
