import SuccessIcon from "../../img/icons/confirm-icon.png";
import ErrorIcon from "../../img/icons/error-icon.png";

import "./form-confirmation.styles.scss";

const FormConfirmation = ({ status, errMsg, closeMsgHandler }) => {
  return (
    <div className="form-confirmation  page-wrapper__padding">
      <div className="form-confirmation__message-container">
        <img
          src={status === "Success" ? SuccessIcon : ErrorIcon}
          alt={status === "Success" ? "Tick mark" : "Red cross - error"}
          className="form-confirmation__img"
        />
        <p className="form-confirmation__message">
          {status === "Success"
            ? "Your message has been sent"
            : "There was a problem submitting your message"}
        </p>
        <p className="form-confirmation__support-text">
          {status === "Success"
            ? "Our team will be in touch shortly"
            : `Error Message: ${errMsg}`}
        </p>
        <button
          className="btn btn-big btn--white form-confirmation__btn"
          data-btn="OK"
          type="submit"
          onClick={closeMsgHandler}
        ></button>
        <div
          onClick={closeMsgHandler}
          className="form-confirmation__close"
        ></div>
      </div>
    </div>
  );
};

export default FormConfirmation;
