import { useQuery, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";

import "./page-not-found.styles.scss";

const PAGE404 = gql`
  query Get404 {
    pageNotFound {
      data {
        attributes {
          errorText
          details
          cta
          icon {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

const PageNotFound = () => {
  const { loading, error, data } = useQuery(PAGE404);
  const navigate = useNavigate();

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const { icon, errorText, details, cta } = data.pageNotFound.data.attributes;

  return (
    <main className="page-404">
      <div className="page-404__inner">
        <img
          src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
          alt={icon.data.attributes.alternativeText}
          className="page-404__img"
        />
        <h1 className="heading-1 txt-decor-letter-1">{errorText}</h1>
        <p className="page-404__text">{details}</p>
        <button
          className="btn btn-big btn--primary"
          data-btn={`${cta}`}
          onClick={() => navigate("/")}
        ></button>
      </div>
    </main>
  );
};

export default PageNotFound;
