import { Link } from "react-router-dom";

import "./news-article.styles.scss";

const NewsArticle = ({ data, sliderItemConfig, promotedFlag }) => {
  return (
    <>
      {data.map(({ id, attributes }) => (
        <div className={`${sliderItemConfig}`} key={id}>
          <div className="blog-article">
            <img
              src={`${process.env.REACT_APP_CMS_MEDIA}${
                promotedFlag
                  ? attributes.mainImg.data?.attributes.url
                  : attributes.previewImg.data.attributes.url
              }`}
              alt={
                promotedFlag
                  ? attributes.mainImgText
                  : attributes.previewImgText
              }
              className="blog-article__img"
            />
            <div className="blog-article__overlay">
              <p className="blog-article__title">{attributes.Title}</p>

              <p className="blog-article__text">{attributes.previewText}</p>

              <Link
                to={`/article/${attributes.slug}`}
                className="blog-article__link"
              >
                Read more
              </Link>

              <div className="blog-article__details-box">
                <p className="blog-article__date">
                  {attributes.Publication_Date}
                </p>
                <Link
                  to={`/category/${attributes.category.data.attributes.slug}`}
                  className="blog-article__category"
                >
                  {attributes.category.data.attributes.Name}
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default NewsArticle;
