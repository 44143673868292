import NewsArticle from "../../components/news-article/news-article.component";

import "./news-block.styles.scss";

const NewsBlock = ({ dataBlock }) => {
  return (
    <div className="news-block">
      <NewsArticle data={dataBlock} sliderItemConfig="news-block__list" />
    </div>
  );
};

export default NewsBlock;
