import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

import SEO from "../../components/seo/seo.component";
import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import MarkdownText from "../../components/markdown-text/markdown-text.component";
import FormConfirmation from "../../components/form-confirmation/form-confirmation.component";
import JobApply from "../../components/job-apply/job-apply.component";

import FbShare from "../../utils/share-plugins/facebook";
import "../../utils/share-plugins/twitter";

import FbIcon from "../../img/share-fb.png";
import TwIcon from "../../img/share-tw.png";
import LiIcon from "../../img/share-li.png";

import "./job-page.styles.scss";

const JOBPOST = gql`
  query GetJobPost($urlPath: String) {
    jobPosts(filters: { urlPath: { eq: $urlPath } }) {
      data {
        id
        attributes {
          title
          article
          job_position {
            data {
              id
              attributes {
                position
              }
            }
          }
          job_locations {
            data {
              id
              attributes {
                location
              }
            }
          }
          job_department {
            data {
              id
              attributes {
                department
              }
            }
          }
          SEO {
            title
            name
            type
            description
            image {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const JobPost = () => {
  const { urlPath } = useParams();

  const { loading, error, data } = useQuery(JOBPOST, {
    variables: { urlPath: urlPath },
  });

  const applicationFormRef = useRef(null);
  const [formSubmissionStatus, setFormSubmissionStatus] = useState("");
  const [submissionError, setSubmisionError] = useState(null);
  const [displayPopUp, setDisplayPopUp] = useState(false);

  useEffect(() => {
    if (
      formSubmissionStatus === "Success" ||
      formSubmissionStatus === "Error"
    ) {
      setDisplayPopUp(true);
    }

    return () => setDisplayPopUp(false);
  }, [formSubmissionStatus]);

  // Data loading start
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const job = data.jobPosts.data[0];
  const {
    SEO: seoData,
    title,
    job_department,
    job_locations,
    article,
  } = job.attributes;
  const siteURL = window.location.href;
  // Data loading finish

  const closePopUpHandler = () => {
    setSubmisionError(null);
    setFormSubmissionStatus("");
  };

  const FbPopOpener = () =>
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${siteURL}&amp;src=sdkpreparse`,
      "",
      "width=700, height=600"
    );

  const TwitterPopOpener = () =>
    window.open(
      `https://twitter.com/intent/tweet?text=New%20job%20post%20-%20${siteURL}`,
      "",
      "width=900, height=750"
    );

  const LinkedInPopOpener = () =>
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${siteURL}`,
      "",
      "width=900, height=750"
    );

  const executeScroll = () =>
    applicationFormRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  return (
    <>
      {seoData && (
        <SEO
          title={seoData.title ? seoData.title : null}
          description={seoData.description ? seoData.description : null}
          name={seoData.name ? seoData.name : null}
          type={seoData.type ? seoData.type : null}
          image={
            seoData.image.data
              ? `${process.env.REACT_APP_CMS_MEDIA}${seoData.image.data.attributes.url}`
              : null
          }
        />
      )}

      <FbShare />
      <div className="job-page-section">
        <main className="job-page page-wrapper page-wrapper__padding">
          <div className="job-page__head">
            <div className="job-page__head-txt">
              <h1 className="heading-1">Role: {title}</h1>
              <div className="job-page__attributes">
                <p className="job-page__department">
                  {job_department.data.attributes.department}
                </p>
                <ul className="job-page__locations">
                  {job_locations.data.map(({ id, attributes }) => (
                    <li className="job-page__location" key={id}>
                      {attributes.location}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <button
              onClick={executeScroll}
              className="btn btn-big btn--white job-page__btn"
              data-btn="Apply Now"
            ></button>
          </div>
          <MarkdownText
            className="job-page__job"
            content={article}
          ></MarkdownText>

          <div className="share">
            <p className="share-text">Share this: </p>
            <img
              src={FbIcon}
              alt="Share on Facebook"
              className="share-btn-img"
              onClick={FbPopOpener}
            />

            <img
              src={TwIcon}
              alt="Share on Twitter"
              className="share-btn-img"
              onClick={TwitterPopOpener}
            />
            <img
              src={LiIcon}
              alt="Share on LinkedIn"
              className="share-btn-img"
              onClick={LinkedInPopOpener}
            />
          </div>
        </main>
      </div>
      <JobApply
        setFormSubmissionStatus={setFormSubmissionStatus}
        setSubmisionError={setSubmisionError}
        setDisplayPopUp={setDisplayPopUp}
        job={job}
        applicationFormRef={applicationFormRef}
      />

      <div style={{ display: `${displayPopUp ? "block" : "none"}` }}>
        <FormConfirmation
          status={formSubmissionStatus}
          errMsg={submissionError}
          closeMsgHandler={closePopUpHandler}
        />
      </div>
    </>
  );
};

export default JobPost;
