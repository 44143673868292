import { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import LoadingPage from "../loading-page/loading-page.component";

import "./home-reasons.styles.scss";

const MOREREASOBS = gql`
  query {
    homeMoreReasons(sort: "onDisplayOrder") {
      data {
        id
        attributes {
          icon {
            data {
              id
              attributes {
                url
              }
            }
          }
          iconALT
          text
          value
        }
      }
    }
  }
`;

const HomeReasons = ({ headers }) => {
  const { loading, data } = useQuery(MOREREASOBS);
  const [reasonsBlock, setReasonsBlock] = useState(null);

  useEffect(() => {
    if (data) {
      setReasonsBlock(data.homeMoreReasons.data);
    }
  }, [data]);

  if (loading) return <LoadingPage />;

  return (
    <>
      {reasonsBlock && (
        <section className="home-reasons">
          <div className="home-reasons__inner-container page-wrapper page-wrapper__padding">
            <div className="home-reasons__inner">
              <h2 className="heading-2 txt-decor-letter-1">{headers.header}</h2>
              <div className="home-reasons__reasons-list">
                {reasonsBlock.map(({ id, attributes }) => (
                  <div className="home-reasons__reason-box" key={id}>
                    <img
                      src={`${process.env.REACT_APP_CMS_MEDIA}${attributes.icon.data.attributes.url}`}
                      alt={attributes.iconALT}
                      className="home-reasons__img"
                    />
                    {/* <AnimatedString string={attributes.value} /> */}
                    <p className="home-reasons__value heading-1-1">
                      {attributes.value}
                    </p>
                    <p className="home-reasons__text">{attributes.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default HomeReasons;
