import { useQuery, gql } from "@apollo/client";

import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import PlatformGuide from "../../components/platform-guide/platform-guide.component";
import WhitepaperForm from "../../components/whitepaper-form/whitepaper-form.component";

import Asset1 from "../../img/whitepaper/whitepaper-info-bg-left.svg";
import Asset2 from "../../img/whitepaper/whitepaper-info-bg-right.svg";
import Asset3 from "../../img/whitepaper/whitepaper-form-bg-left.svg";
import Asset4 from "../../img/whitepaper/whitepaper-form-bg-right.svg";

import "./whitepaper.styles.scss";

const WHITEPAPER = gql`
  query GetWhitePaperLandingPage {
    whitePaperLandingPage {
      data {
        id
        attributes {
          title
          subtitle
          salesPitch {
            title
            bulletPoints {
              id
              bulletPoint
            }
            leftHandParagraphs {
              id
              paragraph
            }
            rightHandParagraphs {
              id
              paragraph
            }
          }
          contactForm {
            title
            fields {
              id
              label
              name
              placeholder
              type
              style
              isRequired
              inputID
              isTextArea
            }
            btn
            consent
            showConsent
            contactChoice
            showContactChoice
            whitePaperPdfUrl
          }
        }
      }
    }
  }
`;

const Whitepaper = () => {
  const { loading, error, data } = useQuery(WHITEPAPER);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const { salesPitch, contactForm } =
    data.whitePaperLandingPage.data.attributes;

  let blockData = data.whitePaperLandingPage.data.attributes;

  return (
    <>
      <PlatformGuide blockData={blockData} download="#download" />

      <section className="whitepaper-info page-wrapper page-wrapper__padding">
        <ul className="whitepaper-info__columns">
          <li className="whitepaper-info__column">
            <h4 className="heading-4">{salesPitch.title}</h4>
            <div className="markdown whitepaper-info__column-text">
              {salesPitch.leftHandParagraphs.map(p => {
                return (
                  <p key={p.id}>{p.paragraph}</p>
                )
              })}
            </div>
          </li>
          <li className="whitepaper-info__column">
            <div className="markdown whitepaper-info__column-text">
              {salesPitch.rightHandParagraphs.map(p => {
                return (
                  <p key={p.id}>{p.paragraph}</p>
                )
              })}
              <ul className="whitepaper-info__list">
                {salesPitch.bulletPoints.map(b => {
                  return (
                    <li key={b.id}>{b.bulletPoint}</li>
                  )
                })}
              </ul>
            </div>
          </li>
        </ul>
        <img
          src={Asset1}
          alt="background decoration"
          className="whitepaper-info__asset1"
        />
        <img
          src={Asset2}
          alt="background decoration"
          className="whitepaper-info__asset2"
        />
      </section>

      <section className="whitepaper-form">
        <div className="whitepaper-form__inner page-wrapper page-wrapper__padding">
          <div className="contact-us">
            <div className="contact-us__head-box">
              <div className="contact-us__heading">
                <h1 className="heading-1 margin-medium-bt txt-decor-letter-1" id="download">Download our Free White Paper</h1>
              </div>
            </div>

            <WhitepaperForm
              formData={contactForm}
              formStyle="contact-us__form margin-medium-tp"
              formName="form-white-paper"
              formId="form-white-papers"
            />
          </div>
          <img
            src={Asset3}
            alt="background decoration"
            className="contact-us__asset3"
          />
          <img
            src={Asset4}
            alt="background decoration"
            className="contact-us__asset4"
          />
        </div>
      </section>
    </>
  );
};

export default Whitepaper;
