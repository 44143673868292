import { useNavigate } from "react-router-dom";

import MarkdownText from "../markdown-text/markdown-text.component";

import "./job-preview.styles.scss";

const JobPreview = ({ job }) => {
  const navigate = useNavigate();

  const {
    id,
    attributes: {
      title,
      job_locations,
      job_department,
      publicationDate,
      description,
      urlPath,
    },
  } = job;
  return (
    <div className="job-preview" key={id}>
      <div className="job-preview__details-container">
        <p className="job-preview__date">{publicationDate}</p>
        <h4 className="heading-4">{title}</h4>
        <div className="job-preview__sub-box">
          {job_department.data ? (
            <p className="job-preview__department">
              {job_department.data.attributes.department}
            </p>
          ) : (
            <></>
          )}
          {job_locations.data ? (
            <ul className="job-preview__locations">
              {job_locations.data.map((location) => (
                <li className="job-preview__location" key={location.id}>
                  {location.attributes.location}
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>
        <MarkdownText
          className="job-preview__description"
          content={description.substring(0, 255) + "..."}
        ></MarkdownText>
      </div>
      <div className="job-preview__button-container">
        <button
          className="btn btn-big btn--white"
          data-btn="Learn more"
          onClick={() => {
            navigate(`/careers/${urlPath}`);
          }}
        ></button>
      </div>
    </div>
  );
};

export default JobPreview;
