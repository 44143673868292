import MarkdownText from "../markdown-text/markdown-text.component";

import "./platform-features.styles.scss";

const PlatformFeatures = ({ blockData }) => {
  const { blockTwoTitle, blockTwoCards, blockTwoFooterCard } = blockData;

  return (
    <div className="platform-features " id="features">
      <div className="page-wrapper page-wrapper__padding platform-features__head">
        <h2 className="heading-2 txt-decor-letter-1">{blockTwoTitle}</h2>
        <ul className="platform-features__items">
          {blockTwoCards.map(({ id, title, description, icon, iconAlt }) => (
            <li className="platform-features__item" key={id}>
              <img
                src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
                alt={`${iconAlt}`}
                className="platform-features__icon"
              />
              <h4 className="heading-4">{title}</h4>
              <MarkdownText
                className="platform-features__text"
                content={description}
              ></MarkdownText>
            </li>
          ))}
        </ul>
      </div>
      <div className="platform-features__feature-block page-wrapper page-wrapper__padding">
        <div className="platform-features__bottom-left">
          <h2 className="heading-2 txt-decor-letter-1">
            {blockTwoFooterCard.title}
          </h2>
          <MarkdownText
            className="platform-features__bottom-text"
            content={blockTwoFooterCard.description}
          ></MarkdownText>
        </div>
        <div className="platform-features__bottom-right">
          <img
            src={`${process.env.REACT_APP_CMS_MEDIA}${blockTwoFooterCard.icon.data.attributes.url}`}
            alt="Open laptop"
            className="platform-features__bottom-image"
          />
        </div>
      </div>
    </div>
  );
};

export default PlatformFeatures;
