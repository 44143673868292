import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import NewsBlock from "../../components/news-block/news-block.component";

import BacgroundDots from "../../img/background-decor/news/news-dots.svg";

import "./news-category.styles.scss";

const CATEGORY = gql`
  query GetBlogCategory($slug: String) {
    categories(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          Name
          slug
          blogs(sort: "Publication_Date:desc") {
            data {
              id
              attributes {
                previewImg {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                previewImgText
                previewText
                Title
                slug
                Publication_Date
                article
                isPromoted
                category {
                  data {
                    id
                    attributes {
                      Name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const NewsCategory = () => {
  const { slug } = useParams();
  const { loading, error, data } = useQuery(CATEGORY, {
    variables: { slug: slug },
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const { Name, blogs } = data.categories.data[0].attributes;

  return (
    <main className="news-cat">
      <div className="news-cat__inner page-wrapper page-wrapper__padding">
        <h2 className="heading-2 txt-decor-letter-1">{Name}</h2>
        <NewsBlock dataBlock={blogs.data} />

        <img
          src={BacgroundDots}
          alt="background decoration"
          className="news-cat__dots-left"
        />
        <img
          src={BacgroundDots}
          alt="background decoration"
          className="news-cat__dots-bottom"
        />
      </div>
    </main>
  );
};

export default NewsCategory;
