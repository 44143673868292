import { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import LoadingPage from "../loading-page/loading-page.component";
import RecCard from "../rec-card/rec-card.component";

import "./home-regulations.styles.scss";

const REGULATIONS = gql`
  query {
    homeRegComps(sort: "onDisplayOrder", pagination: { limit: 100 }) {
      data {
        id
        attributes {
          icon {
            data {
              id
              attributes {
                url
              }
            }
          }
          iconALT
          text
          url
          jurisdiction
          type
        }
      }
    }
  }
`;

const HomeRegulations = ({ headers }) => {
  const { loading, error, data } = useQuery(REGULATIONS);
  const [regBlock, setRegBlock] = useState(null);
  const [licenses, setLicenses] = useState(null);
  const [jurisdictions, setJurisdictions] = useState(null);
  const [standards, setStandards] = useState(null);

  useEffect(() => {
    if (data) {
      setRegBlock(data.homeRegComps.data);
    }
  }, [data]);

  useEffect(() => {
    if (regBlock) {
      const newLicenses = regBlock.filter(
        (entry) => entry.attributes.type === "licenses"
      );
      const newJurisdictions = regBlock.filter(
        (entry) => entry.attributes.type === "jurisdictions"
      );
      const newStandards = regBlock.filter(
        (entry) => entry.attributes.type === "standarts"
      );

      setLicenses(newLicenses);
      setJurisdictions(newJurisdictions);
      setStandards(newStandards);
    }
  }, [regBlock]);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        regBlock && (
          <section className="home-rac" id="regulations">
            <div className="home-rac__inner page-wrapper page-wrapper__padding">
              {licenses && (
                <>
                  <h2 className="heading-2 txt-decor-letter-1">
                    {headers.header}
                  </h2>
                  <h3 className="heading-3 home-rac__h3">{headers.subOne}</h3>
                  <div className="home-rac__card-box">
                    {licenses.map((card) => (
                      <RecCard data={card.attributes} key={card.id} />
                    ))}
                  </div>
                </>
              )}

              {jurisdictions && (
                <>
                  <h3 className="heading-3 home-rac__h3">{headers.subTwo}</h3>
                  <div className="home-rac__card-box">
                    {jurisdictions.map((card) => (
                      <RecCard data={card.attributes} key={card.id} />
                    ))}
                  </div>
                </>
              )}

              {standards && (
                <>
                  <h3 className="heading-3 home-rac__h3">{headers.subThree}</h3>
                  <div className="home-rac__card-box">
                    {standards.map((card) => (
                      <RecCard data={card.attributes} key={card.id} />
                    ))}
                  </div>
                </>
              )}
            </div>
          </section>
        )
      )}
    </>
  );
};

export default HomeRegulations;
