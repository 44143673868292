import { useState, useEffect } from "react";

import FormInput from "../../components/form-input/form-input.component";
import FormConfirmation from "../../components/form-confirmation/form-confirmation.component";
import MarkdownText from "../markdown-text/markdown-text.component";

import { submitForm } from "../../utils/submit-form";

import "./form.styles.scss";

const Form = (props) => {
  const [formSubmissionStatus, setFormSubmissionStatus] = useState("");
  const [submissionError, setSubmisionError] = useState(null);
  const [displayPopUp, setDisplayPopUp] = useState(false);

  useEffect(() => {
    if (
      formSubmissionStatus === "Success" ||
      formSubmissionStatus === "Error"
    ) {
      setDisplayPopUp(true);
    }

    return () => setDisplayPopUp(false);
  }, [formSubmissionStatus]);

  const closePopUpHandler = () => {
    setSubmisionError(null);
    setFormSubmissionStatus("");
  };

  const { formStyle, formName, formId, formData } = props;

  return (
    <>
      <form
        className={`main-form ${formStyle}`}
        name={formName}
        id={formId}
        onSubmit={(e) =>
          submitForm(e, setFormSubmissionStatus, setSubmisionError)
        }
      >
        {formData.input.map(
          ({
            label,
            inputID,
            placeholder,
            type,
            name,
            isTextArea,
            isRequired,
            style,
            id,
          }) => (
            <FormInput
              key={id}
              label={label}
              id={inputID}
              type={type}
              placeholder={placeholder}
              name={name}
              required={isRequired ? "required" : "optional"}
              className={style}
              isTextArea={isTextArea}
            />
          )
        )}

        {formData.showConsent && (
          <div className="main-form__checkbox">
            <label htmlFor="checkbox">
              <MarkdownText content={formData.consent} />
            </label>
            <input id="checkbox" type="checkbox" name="checkbox" required />
          </div>
        )}

        <button
          className="btn btn-big btn--white main-form__btn"
          data-btn={formData.btn}
          type="submit"
        ></button>
      </form>
      <div style={{ display: `${displayPopUp ? "block" : "none"}` }}>
        <FormConfirmation
          status={formSubmissionStatus}
          errMsg={submissionError}
          closeMsgHandler={closePopUpHandler}
        />
      </div>
    </>
  );
};

export default Form;
