import Slider from "../slider/slider.component";
import NewsArticle from "../news-article/news-article.component";

import "./news-slider.styles.scss";

const NewsSlider = ({ dataBlock }) => {
  return (
    <div className="news-slider">
      <Slider
        sliderConfig="news-slider__slider"
        displayDots={false}
        fullCardView={true}
        item={
          <NewsArticle
            data={dataBlock}
            sliderItemConfig="news-slider__blog-article"
          />
        }
      />
    </div>
  );
};

export default NewsSlider;
