export const submitForm = (
  event,
  setFormSubmissionStatus,
  setSubmisionError
) => {
  event.preventDefault();

  const formId = event.target.id;
  const url = `${process.env.REACT_APP_API_BASE}/api/${formId}`;
  let formOutput = {};
  const formData = [...event.target.elements];

  formData
    .filter(
      (input) =>
        input.type === "text" ||
        input.type === "email" ||
        input.type === "textarea" ||
        input.type === "checkbox" ||
        input.type === "hidden"
    )
    .forEach((input) => {
      // Don't send an unchecked checkbox value
      if(input.type === 'checkbox' && !input.checked) {
        return;
      }

      const keyValueToAdd = { [input.name]: input.value };

      formOutput = { ...formOutput, ...keyValueToAdd };
    });

  const postObject = { data: formOutput };

  const postData = async (url = "", data = {}) => {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    return response.json(); // parses JSON response into native JavaScript objects
  };

  (async function () {
    try {
      const test = await postData(url, postObject);
      const succes = await test.data;
      const err = await test.error;

      if (succes) setFormSubmissionStatus("Success");
      if (err) {
        setSubmisionError(err.name);
        setFormSubmissionStatus("Error");
      }
    } catch (err) {
      setSubmisionError(err);
      setFormSubmissionStatus("Error");
    }
  })();

  formData.forEach((input) => {
    if (input.type === "checkbox") input.checked = false;
    input.value = "";
  });
};
