import { Fragment, useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { useWindowSize } from "../../hooks/useWindowSize";

import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import NewsBlock from "../../components/news-block/news-block.component";
import SearchBox from "../../components/search-box/search-box.component";
import NewsArticle from "../../components/news-article/news-article.component";

import BacgroundDots from "../../img/background-decor/news/news-dots.svg";

import "./news.styles.scss";

const BLOGPOSTS = gql`
  query {
    blogs(sort: "Publication_Date:desc", pagination: { limit: 100 }) {
      data {
        id
        attributes {
          previewImg {
            data {
              id
              attributes {
                url
              }
            }
          }
          previewImgText
          previewText
          Title
          slug
          Publication_Date
          article
          isPromoted
          mainImg {
            data {
              id
              attributes {
                url
              }
            }
          }
          mainImgText
          category {
            data {
              id
              attributes {
                Name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

const News = () => {
  const { loading, error, data } = useQuery(BLOGPOSTS);
  const [searchField, setSearchField] = useState("");
  const [blogList, setBlogList] = useState(null);
  const [filteredArticles, setFilteredArticles] = useState(null);
  const [visibleArticlesCount, setVisibleArticlesCount] = useState(6);
  const [width] = useWindowSize();
  const [showPromotedSection, setShowPromotedSection] = useState(true);

  useEffect(() => {
    if (width > 0 && width <= 800) {
      setShowPromotedSection(false);
    }

    return () => {
      setShowPromotedSection(true);
    };
  }, [width]);

  useEffect(() => {
    if (blogList) {
      let arrayForSearch;

      showPromotedSection && searchField.length === 0
        ? (arrayForSearch = blogList.blogs.data.filter(
            (blog) => !blog.attributes.isPromoted
          ))
        : (arrayForSearch = blogList.blogs.data);

      const filterByCategory = arrayForSearch.filter((blog) => {
        return blog.attributes.category.data?.attributes.Name.toLocaleLowerCase().includes(
          searchField
        );
      });

      const filterByDate = arrayForSearch.filter((blog) => {
        return blog.attributes.Publication_Date.includes(searchField);
      });

      const filterByArticleText = arrayForSearch.filter((blog) => {
        return blog.attributes.article
          .toLocaleLowerCase()
          .includes(searchField);
      });

      const filterByTitleText = arrayForSearch.filter((blog) => {
        return blog.attributes.Title.toLocaleLowerCase().includes(searchField);
      });

      const newFilteredArticles = [
        ...new Set([
          ...filterByCategory,
          ...filterByArticleText,
          ...filterByDate,
          ...filterByTitleText,
        ]),
      ];

      setFilteredArticles(newFilteredArticles);
    }
  }, [blogList, searchField, showPromotedSection]);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  !blogList && setBlogList(data);

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  const clearSearch = () => {
    setSearchField("");
  };

  const showMoreArticlesHandler = () => {
    setVisibleArticlesCount((prevValue) => prevValue + 6);
  };

  return (
    <>
      {filteredArticles && (
        <Fragment>
          <section className="news-search ">
            <div className="news-search__inner page-wrapper page-wrapper__padding">
              <h1 className="heading-1 txt-decor-letter-1">Latest News</h1>
              <p className="news-search__text">
                Browse our latest news or use the search bar to find what you’re
                looking for.
              </p>
              <div className="news-search__search-container">
                <SearchBox
                  onSearchHandler={onSearchChange}
                  placeholder="Search for a title, date or category"
                  className="news-search__search-box"
                />
                <div className="news-search__button">
                  <button
                    className="btn btn-big btn--primary btn--search"
                    data-btn="Search"
                    onClick={clearSearch}
                  ></button>
                </div>
              </div>
              {searchField.length > 0 && filteredArticles.length > 0 ? (
                <p className="news-search__text">
                  <span>{filteredArticles.length}</span> results for{" "}
                  {searchField}
                </p>
              ) : (
                <div></div>
              )}
              {searchField.length > 0 && filteredArticles.length === 0 ? (
                <div>
                  <p className="news-search__text">
                    Your search for “<span>{searchField}</span>” did not have
                    any matches.
                  </p>
                  <p className="news-search__text">Suggestions:</p>
                  <ul>
                    <li>Try different keywords</li>
                    <li>Double check your spelling is correct</li>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
          <div className="articles-section">
            {searchField.length === 0 &&
            showPromotedSection &&
            data.blogs.data.filter((blog) => blog.attributes.isPromoted)
              .length > 0 ? (
              <section className="promoted-article page-wrapper page-wrapper__padding">
                <NewsArticle
                  data={data.blogs.data
                    .filter((blog) => blog.attributes.isPromoted)
                    .filter((_, i) => i === 0)}
                  sliderItemConfig="promoted-article__article"
                  promotedFlag={true}
                />

                <img
                  src={BacgroundDots}
                  alt="background decoration"
                  className="promoted-article__dots"
                />
              </section>
            ) : (
              <></>
            )}

            {filteredArticles.length > 0 && (
              <main className="news page-wrapper page-wrapper__padding">
                <NewsBlock
                  dataBlock={filteredArticles.slice(0, visibleArticlesCount)}
                  onClick={clearSearch}
                />

                {filteredArticles.length > visibleArticlesCount && (
                  <button
                    className="btn btn-big btn--white"
                    data-btn="See more news"
                    onClick={showMoreArticlesHandler}
                  ></button>
                )}
                <img
                  src={BacgroundDots}
                  alt="background decoration"
                  className="news__dots"
                />
              </main>
            )}
          </div>
        </Fragment>
      )}
    </>
  );
};

export default News;
