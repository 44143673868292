import { useQuery, gql } from "@apollo/client";

import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import Form from "../../components/form/form.component";

import Asset1 from "../../img/background-decor/contact-us/contact-asset-1.svg";
import Asset2 from "../../img/background-decor/contact-us/contact-asset-2.svg";

import "./contact-us.styles.scss";

const CONTACTS = gql`
  query GetContactUsPage {
    contantUsPage {
      data {
        id
        attributes {
          title
          text
          iconAlt
          icon {
            data {
              id
              attributes {
                url
              }
            }
          }
          contactForm {
            btn
            consent
            showConsent
            input {
              id
              label
              name
              placeholder
              type
              style
              isRequired
              inputID
              isTextArea
            }
          }
        }
      }
    }
    formContacts: contacts {
      data {
        id
        attributes {
          type
          details
          test: iconALT
          iconWhite {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const ContactUs = () => {
  const { loading, error, data } = useQuery(CONTACTS);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const { title, text, icon, iconAlt, contactForm } =
    data.contantUsPage.data.attributes;

  const contactDataEmail = data.formContacts.data.filter(
    ({ attributes }) => attributes.type === "email"
  );

  const contactDataAdrress = data.formContacts.data.filter(
    ({ attributes }) => attributes.type === "address"
  );

  return (
    <section className="section-contact-us page-wrapper page-wrapper__padding">
      <div className="contact-us">
        <div className="contact-us__head-box">
          <div className="contact-us__heading">
            <h1 className="heading-1 margin-medium-bt txt-decor-letter-1">
              {title}
            </h1>
            <p className="contact-us__sub-text">{text}</p>

            <div className="contact-us__contact-items">
              <div className="contact-us__contact-item">
                <img
                  className="contact-us__icon-location"
                  src={`${process.env.REACT_APP_CMS_MEDIA}${contactDataAdrress[0].attributes.iconWhite.data.attributes.url}`}
                  alt={`${contactDataAdrress[0].attributes.iconALT}`}
                />
                <p>{contactDataAdrress[0].attributes.details}</p>
              </div>

              <div className="contact-us__contact-item">
                <img
                  className="contact-us__icon-email"
                  src={`${process.env.REACT_APP_CMS_MEDIA}${contactDataEmail[0].attributes.iconWhite.data.attributes.url}`}
                  alt={`${contactDataEmail[0].attributes.iconALT}`}
                />
                <p>
                  <span>Email</span>
                  {contactDataEmail[0].attributes.details}
                </p>
              </div>
            </div>
          </div>
          <div className="contact-us__icon-box">
            <img
              src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
              alt={`${iconAlt}`}
              className="contact-us__icon"
            />
          </div>
        </div>

        <Form
          formData={contactForm}
          formStyle="contact-us__form margin-medium-tp"
          formName="constact-us"
          formId="form-contact-uses"
        />
      </div>
      <img
        src={Asset1}
        alt="background decoration"
        className="section-contact-us__asset1"
      />
      <img
        src={Asset2}
        alt="background decoration"
        className="section-contact-us__asset2"
      />
    </section>
  );
};

export default ContactUs;
