import { useQuery, gql } from "@apollo/client";

import PageWrapper from "../../components/page-wrapper/page-wrapper.component";
import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";

import Asset1 from "../../img/background-decor/contact-us/contact-asset-1.svg";
import Asset2 from "../../img/background-decor/contact-us/contact-asset-2.svg";

import "./support.styles.scss";

const SUPPORTPAGE = gql`
  query getSupporPage {
    supportPage {
      data {
        attributes {
          title
          sub_title
          sub_title_2
          description
          description_2
          icon {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          integration_support_page {
            text
            btn_url
            btn_text
          }
          channels {
            channel_name
            channel_details
            icon {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Support = () => {
  const { loading, error, data } = useQuery(SUPPORTPAGE);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const {
    title,
    description,
    sub_title,
    icon,
    sub_title_2,
    description_2,
    integration_support_page: portal,
    channels,
  } = data.supportPage.data.attributes;

  return (
    <PageWrapper>
      <div className="support-page__inner">
        <div className="support-page__details">
          <h1 className="heading-1 txt-decor-letter-1">{title ?? ""}</h1>
          <h2 className="heading-2">{sub_title ?? ""}</h2>
          <p className="support-page__text">{description ?? ""}</p>
          <h3 className="heading-3">{sub_title_2 ?? ""}</h3>
          <p>{description_2 ?? ""}</p>
          <div className="support-page__channels-container">
            <div className="support-page__portal">
              <p className="support-page__text">{portal.text ?? ""}</p>
              <a href={portal.btn_url ?? ""} rel="noreferrer" target="_blank">
                <button
                  className="btn btn-big btn--white btn--wrap"
                  data-btn={portal.btn_text ?? ""}
                ></button>
              </a>
            </div>
            <div className="support-page__other-channels">
              {channels && channels.length > 0
                ? channels.map(({ icon, channel_name, channel_details }) => (
                    <div className="support-page__channel-box">
                      <div>
                        {icon ? (
                          <img
                            src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
                            alt={icon.data.attributes.alternativeText ?? ""}
                            className="support-page__channel-img"
                          />
                        ) : null}
                      </div>
                      <div>
                        <p className="support-page__text">
                          {channel_name ?? ""}
                        </p>
                        <a href={`mailto:${channel_details}`}>
                          {channel_details}
                        </a>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
        <div className="support-page__icon">
          <div>
            {icon ? (
              <img
                src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
                alt={icon.data.attributes.alternativeText ?? ""}
                className="support-page__img"
              />
            ) : null}
          </div>
        </div>
      </div>
      <img
        src={Asset1}
        alt="background decoration"
        className="support-page__asset1"
      />
      <img
        src={Asset2}
        alt="background decoration"
        className="support-page__asset2"
      />
    </PageWrapper>
  );
};

export default Support;
