import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

import Navigation from "./routes/navigation/navigation.component";
import Home from "./routes/home/home.component";
import Demo from "./routes/demo/demo.component";
import ContactUs from "./routes/contact-us/contact-us.component";
import Whitepaper from "./routes/whitepaper/whitepaper.component";
import News from "./routes/news/news.component";
import Article from "./routes/article/article.component";
import NewsCategory from "./routes/news-category/news-category.component";
import ArticleLabel from "./routes/article-label/article-label.component";
import IntegrationsPage from "./routes/integrations-hub/integrations-page.component";
import Platform from "./routes/platform/platform.component";
import WhoWeAre from "./routes/who-we-are/who-we-are.component";
import LegalDisclaimer from "./routes/legal-disclaimer/legal-disclaimer.component";
import CookiePolicy from "./routes/cookie-policy/cookie-policy.component";
import PrivacyPolicy from "./routes/privacy-policy/privacy-policy.component";
import Career from "./routes/career/career.component";
import JobPost from "./routes/job-page/job-page.component";
import Support from "./routes/support/support.component";
import PageNotFound from "./routes/page-not-found/page-not-found.component";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: `${process.env.REACT_APP_API_BASE}/graphql`,
  }),
});

const App = () => {
  const helmetContext = {};
  return (
    <ApolloProvider client={client}>
      <HelmetProvider context={helmetContext}>
        <Routes>
          <Route path="/" element={<Navigation />}>
            <Route index element={<Home />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="request-demo" element={<Demo />} />
            <Route path="news" element={<News />} />
            <Route path="article/:slug" element={<Article />} />
            <Route path="category/:slug" element={<NewsCategory />} />
            <Route path="label/:slug" element={<ArticleLabel />} />
            <Route path="integration-hub" element={<IntegrationsPage />} />
            <Route path="platform" element={<Platform />} />
            <Route path="who-we-are" element={<WhoWeAre />} />
            <Route path="legal" element={<LegalDisclaimer />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="careers" element={<Career />} />
            <Route path="careers/:urlPath" element={<JobPost />} />
            <Route path="support" element={<Support />} />
            <Route path="how-to-choose-best-igaming-platform-strategy" element={<Whitepaper />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </HelmetProvider>
    </ApolloProvider>
  );
};

export default App;
