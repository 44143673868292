import { useQuery, gql } from "@apollo/client";

import LoadingPage from "../loading-page/loading-page.component";

import "./social.styles.scss";

const SOCIALS = gql`
  query GetSocials {
    socials {
      data {
        id
        attributes {
          iconALT
          link
          icon {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Social = () => {
  const { loading, error, data } = useQuery(SOCIALS);

  if (loading) return <LoadingPage />;
  if (error) return <></>;

  const socials = data.socials.data;

  return (
    <div className="social footer-flex-row">
      <p className="social__title margin-small-bt">Socials</p>
      <ul className="social__links">
        {socials.map(({ id, attributes }) => (
          <li className="social__link" key={id}>
            <a href={attributes.link} className="social__url">
              <img
                src={`${process.env.REACT_APP_CMS_MEDIA}${attributes.icon.data.attributes.url}`}
                alt={attributes.iconALT}
                className="social__icon"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Social;
