import { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";

import LoadingPage from "../loading-page/loading-page.component";
import ConnectedCard from "../connected-card/connected-card.component";

import "./home-connected.styles.scss";

const GETHOMECONNECTED = gql`
  query {
    homeConnecteds(sort: "onDisplayOrder") {
      data {
        id
        attributes {
          header
          details
          side
          icon {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const HomeConnected = ({ headers }) => {
  const { loading, error, data } = useQuery(GETHOMECONNECTED);
  const [blockData, setBlockData] = useState(null);

  useEffect(() => {
    if (data) {
      setBlockData(data.homeConnecteds.data);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        blockData && (
          <section
            className="section-connected "
            // style={{ height: "100vh", overflow: "scroll" }}
          >
            <div className="section-connected__inner page-wrapper page-wrapper__padding">
              <h2 className="heading-2 section-connected__heading txt-decor-letter-1 margin-huge-bt">
                {headers.header}
              </h2>
              {blockData
                // .filter((a, i) => i === 0)
                .map((card) => (
                  <ConnectedCard data={card} key={card.id} />
                ))}
            </div>
          </section>
        )
      )}
    </>
  );
};

export default HomeConnected;
