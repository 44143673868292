import { useEffect, useRef, useState } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { useWindowSize } from "../../hooks/useWindowSize";

import OrangeRight from "../../img/background-decor/home/orange-right.png";
import OrangeLeft from "../../img/background-decor/home/orange-left.png";
import DarkLeft from "../../img/background-decor/home/dark-left.png";
import DarkRight from "../../img/background-decor/home/dark-right.png";

import "./connected-card.styles.scss";

const ConnectedCard = (data) => {
  const { id, attributes } = data.data;
  const { header, details, side, icon } = attributes;

  const [width] = useWindowSize();
  const [animationOffset, setAnimationOffset] = useState(1);
  const [animationHeight, setAnimationHeight] = useState(1);
  const gradientRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: gradientRef,
    offset: [`0 ${animationOffset}`, "0 0"],
  });

  const scrollProgressWithSpring = useSpring(scrollYProgress, {
    stiffness: 40,
    damping: 10,
    restDelta: 0.001,
  });

  useEffect(() => {
    const offset = width >= 600 ? 1.1 : 2;
    const h = width >= 600 ? 0.45 : 0.35;
    setAnimationOffset(offset);
    setAnimationHeight(h);
  }, [width]);

  let gradientRight = useTransform(
    scrollProgressWithSpring,
    [0, animationHeight],
    ["0%", "33%"]
  );
  let gradientLeft = useTransform(
    scrollProgressWithSpring,
    [0, animationHeight],
    ["0%", "-33%"]
  );
  let iconRightX = useTransform(
    scrollProgressWithSpring,
    [0, animationHeight],
    ["-100%", "0%"]
  );
  let iconLeftX = useTransform(
    scrollProgressWithSpring,
    [0, animationHeight],
    ["100%", "0%"]
  );
  let iconRightY = useTransform(
    scrollProgressWithSpring,
    [0, animationHeight],
    ["50%", "0%"]
  );
  let iconLeftY = useTransform(
    scrollProgressWithSpring,
    [0, animationHeight],
    ["50%", "0%"]
  );

  return (
    <div className={`connected-card__row ${"row" + id}`}>
      <div
        className={`connected-card__article ${
          side === "left"
            ? "connected-card__article-left"
            : "connected-card__article-right"
        }`}
      >
        <h3 className="connected-card__article-header heading-3">{header}</h3>
        <p className="connected-card__article-text">{details}</p>
      </div>
      <div
        ref={gradientRef}
        className={
          side === "left"
            ? "connected-card__decor-right"
            : "connected-card__decor-left"
        }
      >
        <img
          src={side === "left" ? OrangeRight : OrangeLeft}
          alt="Background decoration"
          className={
            side === "left"
              ? "connected-card__orange-right"
              : "connected-card__orange-left"
          }
        />
        <motion.div
          style={{
            translateX: side === "right" ? gradientRight : gradientLeft,
          }}
          className={
            side === "left"
              ? `connected-card__gradient-box-right `
              : `connected-card__gradient-box-left`
          }
        >
          <img
            src={side === "left" ? DarkRight : DarkLeft}
            alt="Background decoration"
            className={
              side === "left"
                ? `connected-card__gradient-right `
                : `connected-card__gradient-left`
            }
          />

          <motion.img
            src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
            alt="Decor icon"
            style={{
              translateX: side === "right" ? iconRightX : iconLeftX,
              translateY: side === "right" ? iconRightY : iconLeftY,
            }}
            className={`connected-card__icon ${"connected-card__icon" + id} ${
              side === "left"
                ? "connected-card__icon-right"
                : "connected-card__icon-left"
            }`}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default ConnectedCard;
