import { useQuery, gql } from "@apollo/client";

import PlatformBenefits from "../../components/platform-benefits/platform-benefits.component";
import PlatformFeatures from "../../components/platform-features/platform-features.component";
import PlatformFrontEnd from "../../components/platform-frontend/platform-frontend.component";
import PlatformGuide from "../../components/platform-guide/platform-guide.component";
import HomeGetInTouch from "../../components/home-getInTouch/home-getInTouch.component";
import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";

import "./platform.styles.scss";

const PLATFORMPAGE = gql`
  query {
    platformPage {
      data {
        id
        attributes {
          title
          blockOneTitle
          blockOneText
          blockOneCards(sort: "onDisplayOrder", pagination: { limit: 100 }) {
            title
            description
            id
            icon {
              data {
                id
                attributes {
                  caption
                  url
                }
              }
            }
            iconAlt
          }

          blockTwoTitle
          blockTwoText
          blockTwoCards(sort: "onDisplayOrder", pagination: { limit: 100 }) {
            title
            description
            id
            icon {
              data {
                id
                attributes {
                  caption
                  url
                }
              }
            }
            iconAlt
          }
          blockTwoFooterCard {
            title
            description
            id
            icon {
              data {
                id
                attributes {
                  caption
                  url
                }
              }
            }
            iconAlt
          }
          blockThreeTitle
          blockThreeText
          blockThreeCards(sort: "onDisplayOrder", pagination: { limit: 100 }) {
            title
            description
            id
            icon {
              data {
                id
                attributes {
                  caption
                  url
                }
              }
            }
            iconAlt
          }
          blockFourTitle
          blockFourText
          blockFourGallery {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
    homePage {
      data {
        attributes {
          getInTouchBlock {
            header
            sub
          }
        }
      }
    }
    whitePaperLandingPage {
      data {
        id
        attributes {
          title
          subtitle
        }
      }
    }
  }
`;

const Platform = () => {
  const { loading, error, data } = useQuery(PLATFORMPAGE);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const {
    title,
    blockOneTitle,
    blockOneText,
    blockOneCards,
    blockTwoTitle,
    blockTwoCards,
    blockTwoFooterCard,
    blockThreeTitle,
    blockThreeCards,
    blockFourTitle,
    blockFourGallery,
  } = data.platformPage.data.attributes;

  const benefits = { title, blockOneTitle, blockOneText, blockOneCards };
  const features = { blockTwoTitle, blockTwoCards, blockTwoFooterCard };
  const frontEnd = {
    blockThreeTitle,
    blockThreeCards,
    blockFourTitle,
    blockFourGallery,
  };
  const guide = data.whitePaperLandingPage.data.attributes;
  const inTouchBlock = data.homePage.data.attributes;

  return (
    <main className="platform ">
      <PlatformBenefits blockData={benefits} />
      <PlatformFeatures blockData={features} />
      <PlatformFrontEnd blockData={frontEnd} />
      <PlatformGuide blockData={guide} download="/how-to-choose-best-igaming-platform-strategy" />

      <div className="platform-getInTouch">
        <HomeGetInTouch headers={inTouchBlock.getInTouchBlock} />
      </div>
    </main>
  );
};

export default Platform;
