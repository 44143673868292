import { Fragment, useRef } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import useElementSize from "../../hooks/useElementSize";

import Footer from "../../components/footer/footer.component";
import SEO from "../../components/seo/seo.component";
import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import TopBar from "../../components/top-bar/top-bar.component";
import NavLinks from "../../components/nav-links/nav-links.component";

import logo from "../../img/ps-logo-white.png";

import "../../styles/common.styles.scss";
import "./navigation.styles.scss";

const MENU = gql`
  query GetNav {
    navigationMenu {
      data {
        id
        attributes {
          navLink(sort: "link.sortOrder") {
            id
            link {
              page
              url
              sortOrder
            }
            type
            dropdownLinks(sort: "sortOrder") {
              id
              page
              url
              sortOrder
            }
          }
        }
      }
    }
  }
`;

const SITESEO = gql`
  query {
    siteSeo {
      data {
        id
        attributes {
          SEO {
            title
            name
            description
            type
            image {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const menuBtn = useRef(null);
  const menuIcon = useRef(null);
  const menuContainer = useRef(null);

  const [navRef, { width }] = useElementSize();
  const { loading, error, data } = useQuery(MENU);
  const { loading: seoLoading, data: seoData } = useQuery(SITESEO);

  const mobileMenu = () => {
    if (width <= 1344) {
      menuIcon.current.classList.toggle("nav-container__menu-close");
      menuContainer.current.classList.toggle("nav-container__menu-open");
    }
  };

  return (
    <>
      {loading || seoLoading ? (
        <LoadingPage />
      ) : error ? (
        <ErrorPage />
      ) : (
        <Fragment>
          {seoData.siteSeo.data && (
            <SEO
              title={
                seoData.siteSeo.data.attributes.SEO.title
                  ? seoData.siteSeo.data.attributes.SEO.title
                  : null
              }
              description={
                seoData.siteSeo.data.attributes.SEO.description
                  ? seoData.siteSeo.data.attributes.SEO.description
                  : null
              }
              name={
                seoData.siteSeo.data.attributes.SEO.name
                  ? seoData.siteSeo.data.attributes.SEO.name
                  : null
              }
              type={
                seoData.siteSeo.data.attributes.SEO.type
                  ? seoData.siteSeo.data.attributes.SEO.type
                  : null
              }
              image={
                seoData.siteSeo.data.attributes.SEO.image.data
                  ? `${process.env.REACT_APP_CMS_MEDIA}${seoData.siteSeo.data.attributes.SEO.image.data.attributes.url}`
                  : null
              }
            />
          )}

          {location.pathname === '/' ? <TopBar /> : <></>}
          <nav ref={navRef} className="nav-container no-top-bar">
            <div className="nav-container__inner page-wrapper page-wrapper__padding">
              <Link className="logo-box" to="/">
                <img src={logo} alt="Pragmatic Solutions" className="logo" />
              </Link>
              <div
                ref={menuBtn}
                onClick={mobileMenu}
                className="nav-container__menu nav-container__hidden"
              >
                <div ref={menuIcon} className="nav-container__menu-icon"></div>
              </div>
              <div ref={menuContainer} className="nav-container__mobile-menu">
                <NavLinks data={data} menuFunc={mobileMenu} />
                <div className="nav-container__button-box">
                  <button
                    className="btn btn-big nav-container__button"
                    data-btn="Request a demo"
                    onClick={() => {
                      navigate("/request-demo");
                      mobileMenu();
                    }}
                  ></button>
                </div>
              </div>
            </div>
          </nav>
          <Outlet />
          <Footer />
        </Fragment>
      )}
    </>
  );
};

export default Navigation;
