import { useQuery, gql } from "@apollo/client";

import LoadingPage from "../../components/loading-page/loading-page.component";

import "./contacts.styles.scss";

const CONTACTS = gql`
  query GetFooterContacts {
    footerContacts: contacts {
      data {
        contactId: id
        attributes {
          type
          details
          iconALT
          icon {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Contacts = () => {
  const { loading, error, data } = useQuery(CONTACTS);

  if (loading) return <LoadingPage />;
  if (error) return <></>;

  const contactData = data.footerContacts.data;

  return (
    <div className="contact-block footer-flex-row">
      <p className="contact-block__name margin-small-bt">Contact</p>
      <ul className="contact-block__list">
        {contactData.map(({ contactId, attributes }) => (
          <li className="contact-block__item" key={contactId}>
            <img
              src={`${process.env.REACT_APP_CMS_MEDIA}${attributes.icon.data.attributes.url}`}
              alt={`${attributes.iconALT}`}
              className="contact-block__icon"
            />
            <p className="contact-block__text">
              {attributes.type === "phone" ? (
                <a href={`tel:${attributes.details}`}>{attributes.details}</a>
              ) : attributes.type === "email" ? (
                <a className="a-alt" href={`mailto:${attributes.details}`}>
                  {attributes.details}
                </a>
              ) : (
                attributes.details
              )}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Contacts;
