import { useQuery, gql } from "@apollo/client";

import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import HomeHero from "../../components/home-hero/home-hero.component";
import HomeUsp from "../../components/home-usp/home-usp.component";
import HomeHub from "../../components/home-hub/home-hub.component";
import HomeConnected from "../../components/home-connected/home-connected.component";
import HomeReasons from "../../components/home-reasons/home-reasons.component";
import HomeRegulations from "../../components/home-regulations/home-regulations.component";
import HomeWhy from "../../components/home-why/home-why.component";
import HomeNews from "../../components/home-news/home-news.component";
import HomeGetInTouch from "../../components/home-getInTouch/home-getInTouch.component";

import "../../styles/common.styles.scss";
import "./home.styles.scss";

const HOMEPAGE = gql`
  query {
    homePage {
      data {
        id
        attributes {
          whyPragmaticSolutions {
            header
            sub
          }
          newsBlock {
            header
          }
          getInTouchBlock {
            header
            sub
          }
          complianceBlock {
            header
            subOne
            subTwo
            subThree
          }
          team {
            header
          }
          moreReasonsToUse {
            header
          }
          everythingConnected {
            header
          }
          uniqueSellingPoints {
            header
          }
          hero {
            header
          }
          heroImg {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          heroVideo {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          heroVideoCover {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          heroImgMobile {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

const Home = () => {
  const { loading, error, data } = useQuery(HOMEPAGE);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const homeData = data.homePage.data.attributes;

  return (
    <main className="home-main">
      <HomeHero
        headers={homeData.hero}
        img={homeData.heroImg}
        imgMobile={homeData.heroImgMobile}
        video={homeData.heroVideo}
        videoCover={homeData.heroVideoCover}
        key="1"
      />
      <HomeUsp headers={homeData.uniqueSellingPoints} key="2" />
      <HomeConnected headers={homeData.everythingConnected} key="3" />
      <HomeReasons headers={homeData.moreReasonsToUse} key="6" />
      <HomeRegulations headers={homeData.complianceBlock} key="7" />
      <HomeWhy headers={homeData.whyPragmaticSolutions} key="8" />
      <HomeHub key="4" />
      <HomeNews headers={homeData.newsBlock} key="9" />
      <HomeGetInTouch headers={homeData.getInTouchBlock} key="10" />
    </main>
  );
};

export default Home;
