import MarkdownText from "../markdown-text/markdown-text.component";

import "./our-vision.styles.scss";

const OurVision = ({ dataArr }) => {
  const {
    sectionTwoTitle,
    sectionTwoHeader,
    sectionTwoSteps,
    sectionTwoSubHeader,
  } = dataArr;

  return (
    <div className="vision">
      <h2 className="heading-2 txt-decor-letter-1">{sectionTwoTitle}</h2>
      <p className="heading-1 vision__head">{sectionTwoHeader}</p>
      <p className="vision__sub-head heading-2">{sectionTwoSubHeader}</p>
      <ul className="vision__items">
        {sectionTwoSteps.map(({ id, icon, iconAlt, text }) => (
          <li className="vision__item" key={id}>
            <img
              src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
              alt={iconAlt}
              className="vision__image"
            />
            <MarkdownText
              className="vision__text"
              content={text}
            ></MarkdownText>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OurVision;
