import { HashLink as Link } from "react-router-hash-link";
import Dropdown from "rsuite/Dropdown";

import "./nav-links.styles.scss";

const NavLinks = ({ data, menuFunc }) => {
  return (
    <div className="nav-box">
      {data.navigationMenu.data.attributes.navLink.map((link) => (
        <div className="nav-box__item" key={link.id}>
          {link.type === "dropdown" ? (
            <Dropdown title={link.link.page}>
              {link.dropdownLinks.map((dLink) => (
                <Dropdown.Item key={dLink.id}>
                  <Link onClick={menuFunc} to={dLink.url}>
                    {dLink.page}
                  </Link>
                </Dropdown.Item>
              ))}
            </Dropdown>
          ) : (
            <Link onClick={menuFunc} to={link.link.url}>
              {link.link.page}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

export default NavLinks;
