import { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";

import FormInput from "../form-input/form-input.component";
import LoadingPage from "../../components/loading-page/loading-page.component";

import "./job-apply.styles.scss";

const FORM = gql`
  query GetApplicationForm {
    careerPage {
      data {
        attributes {
          applicationForm {
            title
            supportText
            placeholderFirstName
            placeholderLastName
            placeholderEmail
            placeholderPhone
            placeholderProfile
            placeholderResume
            placeholderCoverLetter
            btn
          }
        }
      }
    }
  }
`;

const APPLYFORJOB = gql`
  mutation (
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $linkedInProfile: String
    $resume: ID
    $coverLetter: ID
    $jobID: String
    $jobName: String
    $resumeTitle: String
    $coverLetterTitle: String
  ) {
    createJobApplyForm(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        linkedInProfile: $linkedInProfile
        resume: $resume
        coverLetter: $coverLetter
        jobID: $jobID
        jobName: $jobName
        resumeTitle: $resumeTitle
        coverLetterTitle: $coverLetterTitle
      }
    ) {
      data {
        id
        attributes {
          firstName
          lastName
          email
          phone
          linkedInProfile
          resume {
            data {
              id
            }
          }
          coverLetter {
            data {
              id
            }
          }
          jobID
          jobName
        }
      }
    }
  }
`;

const FILEUPLOAD = gql`
  mutation SingleImageUpload($file: Upload!) {
    upload(file: $file) {
      data {
        id
        attributes {
          url
        }
      }
    }
  }
`;

const defaultFormFields = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  linkedinProfile: "",
};

const JobApply = (props) => {
  const { loading, error, data } = useQuery(FORM);
  const [submitJobApplication] = useMutation(APPLYFORJOB);
  const [uploadFile] = useMutation(FILEUPLOAD);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { firstName, lastName, email, phone, linkedinProfile } = formFields;
  const [resumeFile, setResumeFile] = useState(null);
  const [coverFile, setCoverFile] = useState(null);
  const [resumeFileInputValue, setResumeFileInputValue] = useState("");
  const [coverLetterFileInputValue, setCoverLetterFileInputValue] =
    useState("");

  const {
    setFormSubmissionStatus,
    setSubmisionError,
    setDisplayPopUp,
    job,
    applicationFormRef,
  } = props;

  const formChangeHandler = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  const onSubmitJobApplication = async (e) => {
    e.preventDefault();

    if (!resumeFile) {
      setFormSubmissionStatus("Error");
      setSubmisionError(
        "No resume file found - please add the file to proceed!"
      );
      setDisplayPopUp(true);
      return;
    }

    if (!coverFile) {
      setFormSubmissionStatus("Error");
      setSubmisionError(
        "No cover letter file found - please add the file to proceed!"
      );
      setDisplayPopUp(true);
      return;
    }

    const responseResume = await uploadFile({
      variables: {
        file: resumeFile,
      },
    });

    const responseCover = await uploadFile({
      variables: {
        file: coverFile,
      },
    });

    submitJobApplication({
      variables: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        linkedInProfile: linkedinProfile,
        resume: responseResume.data.upload.data.id,
        coverLetter: responseCover.data.upload.data.id,
        jobID: job.id,
        jobName: job.attributes.title,
        resumeTitle: responseResume.data.upload.data.attributes.url,
        coverLetterTitle: responseCover.data.upload.data.attributes.url,
      },
    }).catch(() => {
      setFormSubmissionStatus("Error");
      setSubmisionError(
        "We encountered an error submitting your job applicaiton, please try one more time."
      );
      setDisplayPopUp(true);
    });

    setFormSubmissionStatus("Success");
    setDisplayPopUp(true);
    setFormFields(defaultFormFields);
    setResumeFileInputValue("");
    setCoverLetterFileInputValue("");
  };

  function onResumeChange({
    target: {
      validity,
      files: [file],
    },
  }) {
    if (validity.valid) {
      setResumeFile(file);
      setResumeFileInputValue(file.name);
    }
  }

  function onCoverLetterChange({
    target: {
      validity,
      files: [file],
    },
  }) {
    if (validity.valid) {
      setCoverFile(file);
      setCoverLetterFileInputValue(file.name);
    }
  }

  if (loading) return <LoadingPage />;
  if (error) return <></>;

  const {
    title,
    supportText,
    placeholderFirstName,
    placeholderLastName,
    placeholderEmail,
    placeholderPhone,
    placeholderProfile,
    placeholderResume,
    placeholderCoverLetter,
    btn,
  } = data.careerPage.data.attributes.applicationForm;

  return (
    <div ref={applicationFormRef} className="apply-form-container">
      <div className="apply-form-heading page-wrapper page-wrapper__padding">
        <h2 className="heading-1">{title ? title : "Join The Team"}</h2>
        <h4 className="heading-4">
          {supportText ? supportText : "Join Pragmatic team"}
        </h4>
      </div>
      <form
        onSubmit={onSubmitJobApplication}
        className="apply-form page-wrapper page-wrapper__padding"
      >
        <div className="apply-form__text-inputs">
          <FormInput
            placeholder={`${
              placeholderFirstName ? placeholderFirstName : "First Name"
            }*`}
            type="text"
            name="firstName"
            required
            value={firstName}
            onChange={formChangeHandler}
          />
          <FormInput
            placeholder={`${
              placeholderLastName ? placeholderLastName : "Last Name"
            }*`}
            type="text"
            name="lastName"
            required
            value={lastName}
            onChange={formChangeHandler}
          />
          <FormInput
            placeholder={`${placeholderEmail ? placeholderEmail : "Email"}*`}
            type="email"
            name="email"
            required
            value={email}
            onChange={formChangeHandler}
          />
          <FormInput
            placeholder={`${placeholderPhone ? placeholderPhone : "Phone"}*`}
            type="text"
            name="phone"
            required
            value={phone}
            onChange={formChangeHandler}
          />
          <FormInput
            placeholder={
              placeholderProfile ? placeholderProfile : "LinkedIn Profile"
            }
            type="text"
            name="linkedinProfile"
            value={linkedinProfile}
            onChange={formChangeHandler}
          />
        </div>
        <div className="apply-form__file-inputs">
          <input
            id="resume"
            type="file"
            name="uploadResume"
            onChange={onResumeChange}
            className="apply-form__file-input"
          />

          <label htmlFor="resume">
            {resumeFileInputValue === ""
              ? `${
                  placeholderResume ? placeholderResume : "Upload Resume / CV"
                }*`
              : `${resumeFileInputValue}`}
          </label>
          <input
            id="coverLetter"
            type="file"
            name="uploadCoverLetter"
            onChange={onCoverLetterChange}
            className="apply-form__file-input"
          />
          <label htmlFor="coverLetter">
            {coverLetterFileInputValue === ""
              ? `${
                  placeholderCoverLetter
                    ? placeholderCoverLetter
                    : "Upload Cover Letter"
                }*`
              : `${coverLetterFileInputValue}`}
          </label>
        </div>

        <button
          className="btn btn-big btn--outline-white apply-form__btn"
          type="submit"
          data-btn={btn ? btn : "Submit Application"}
        ></button>
      </form>
    </div>
  );
};

export default JobApply;
