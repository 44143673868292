import PlatformReview from "../platform-review/platform-review.component";
import MarkdownText from "../markdown-text/markdown-text.component";

import "./platform-frontend.styles.scss";

const PlatformFrontEnd = ({ blockData }) => {
  const { blockThreeTitle, blockThreeCards } = blockData;

  return (
    <div className="platform-front" id="front-end-features">
      <div className="page-wrapper page-wrapper__padding platform-front__head">
        <h2 className="heading-2 txt-decor-letter-1">{blockThreeTitle}</h2>
        <div className="platform-front__feature-block">
          <ul className="platform-front__items">
            {blockThreeCards.map(
              ({ id, title, description, icon, iconAlt }) => (
                <li className="platform-front__item" key={id}>
                  <img
                    src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
                    alt={`${iconAlt}`}
                    className="platform-front__icon"
                  />
                  <h3 className="heading-3">{title}</h3>
                  <MarkdownText
                    className="platform-front__text"
                    content={description}
                  ></MarkdownText>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
      <PlatformReview blockData={blockData} />
    </div>
  );
};

export default PlatformFrontEnd;
