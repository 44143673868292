import { Link } from "react-router-dom";

import { useWindowSize } from "../../hooks/useWindowSize";

import MarkdownText from "../markdown-text/markdown-text.component";

import Decor from "../../img/background-decor/footer/Asset-3.svg";

import "./footer-legal.styles.scss";

const Legal = ({ legalData, logos }) => {
  const [footerWidth] = useWindowSize();
  const { copyright, links, disclaimer, protection } = legalData;

  return (
    <section className="legal">
      <p className="legal__copyright">{copyright}</p>
      <ul className="legal__links">
        {links.map(({ id, page, url }) => (
          <li className="legal__link" key={id}>
            <Link className="legal__url" to={url}>
              {page}
            </Link>
          </li>
        ))}
      </ul>
      <ul className="legal__icons ">
        {logos.map(({ id, img, url, width }, i) => (
          <li className="legal__icon-box" key={id}>
            <a href={url} target="_blank" rel="noreferrer">
              <img
                src={`${process.env.REACT_APP_CMS_MEDIA}${img.data.attributes.url}`}
                alt={img.data.attributes.alternativeText}
                className="legal__icon-logo"
                style={{
                  width: `${
                    footerWidth <= 600 ? width * (i === 0 ? 0.85 : 0.66) : width
                  }px`,
                }}
              />
            </a>
          </li>
        ))}
      </ul>
      <MarkdownText
        className="legal__text-1 markdown__white-link"
        content={disclaimer}
      ></MarkdownText>
      <MarkdownText
        className="legal__text-2 markdown__white-link"
        content={protection}
      ></MarkdownText>

      <img src={Decor} alt="Decoration" className="legal__decor" />
    </section>
  );
};

export default Legal;
