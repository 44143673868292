import SuccessIcon from "../../img/icons/confirm-icon.png";
import ErrorIcon from "../../img/icons/error-icon.png";

import "./whitepaper-form-confirmation.styles.scss";

const WhitepaperFormConfirmation = ({ status, errMsg, closeMsgHandler, pdf }) => {
  const downloadWhitepaper = () => {
    fetch('https://pragmatic.solutions/downloads/' + pdf, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
    .then(res => res.blob())
    .then(res => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', pdf);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      // aElement.setAttribute('href', href);
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
      closeMsgHandler();
    });
  }

  return (
    <div className="whitepaper-form-confirmation  page-wrapper__padding">
      <div className="whitepaper-form-confirmation__message-container">
        <img
          src={status === "Success" ? SuccessIcon : ErrorIcon}
          alt={status === "Success" ? "Tick mark" : "Red cross - error"}
          className="whitepaper-form-confirmation__img"
        />
        <p className="whitepaper-form-confirmation__message">
          {status === "Success"
            ? "The White Paper is ready for Download"
            : "There was a problem submitting your request"}
        </p>
        <p className="whitepaper-form-confirmation__support-text">
          {status === "Success"
            ? "Click below to get your free copy."
            : `Error Message: ${errMsg}`}
        </p>
        <button
          className="btn btn-big btn--white whitepaper-form-confirmation__cta"
          data-btn="Download"
          type="button"
          onClick={downloadWhitepaper}
        ></button>
        <div
          onClick={closeMsgHandler}
          className="whitepaper-form-confirmation__close"
        ></div>
      </div>
    </div>
  );
};

export default WhitepaperFormConfirmation;
