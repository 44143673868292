import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, name, type, image }) => {
  const defaultTitle =
    "Pragmatic Solutions - iGaming Platform (PAM) for Regulated Markets";
  const defaultDescription =
    "API based iGaming platform solution giving clients full control over of their gaming product. Speed, flexibility, security, scalability and value.";
  const defaultType = "website";
  const defaultName = "Pragmatic Solutions";

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title ? title : defaultTitle}</title>
      <meta
        name="description"
        content={description ? description : defaultDescription}
      />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type ? type : defaultType} />
      <meta property="og:title" content={title ? title : defaultTitle} />
      <meta
        property="og:description"
        content={description ? description : defaultDescription}
      />
      <meta property="og:image" content={image ? image : null} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name ? name : defaultName} />
      <meta name="twitter:card" content={type ? type : defaultType} />
      <meta name="twitter:title" content={title ? title : defaultTitle} />
      <meta
        name="twitter:description"
        content={description ? description : defaultDescription}
      />
      {/* End Twitter tags */}
    </Helmet>
  );
};

export default SEO;
