import Slider from "../slider/slider.component";

import "./platform-review.styles.scss";

const PlatformReview = ({ blockData }) => {
  const { blockFourTitle, blockFourGallery } = blockData;

  return (
    <div className="platform-review page-wrapper">
      <h2 className="heading-2 txt-decor-letter-1">{blockFourTitle}</h2>

      <div className="platform-review__slider-container">
        <Slider
          sliderConfig="platform-review__slider"
          displayDots={true}
          dots={blockFourGallery.data.length}
          dotsClass="platform-review__slider-dot"
          item={blockFourGallery.data.map(({ id, attributes }) => (
            <div className="platform-review__slider-item" key={id}>
              <div className="platform-review__image-box">
                <img
                  src={`${process.env.REACT_APP_CMS_MEDIA}${attributes.url}`}
                  alt="slider component"
                  className="platform-review__image"
                ></img>
              </div>
            </div>
          ))}
        />
      </div>
    </div>
  );
};

export default PlatformReview;
