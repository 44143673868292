import { gql, useQuery } from "@apollo/client";

import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import MarkdownText from "../../components/markdown-text/markdown-text.component";

import OurVision from "../../components/our-vision/our-vision.component";

import DotsTop from "../../img/background-decor/who-we-are/we-dots-top.svg";
import DotsBottom from "../../img/background-decor/who-we-are/we-dots-bottom.svg";

import "./who-we-are.styles.scss";

const WHOWEAREPAGE = gql`
  query {
    whoWeArePage {
      data {
        id
        attributes {
          pageTitle
          sectionOneTitle
          sectionOneText
          sectionOneImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          sectionOneImageAlt
          sectionTwoTitle
          sectionTwoHeader
          sectionTwoSubHeader
          sectionTwoSteps(sort: "onDisplayOrder") {
            id
            icon {
              data {
                id
                attributes {
                  url
                }
              }
            }
            iconAlt
            text
          }
          sectionThreeTitle
          sectionThreeText
        }
      }
    }
  }
`;

const WhoWeAre = () => {
  const { loading, error, data } = useQuery(WHOWEAREPAGE);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const {
    pageTitle,
    sectionOneTitle,
    sectionOneText,
    sectionOneImage,
    sectionOneImageAlt,
    sectionTwoTitle,
    sectionTwoHeader,
    sectionTwoSubHeader,
    sectionTwoSteps,
    sectionThreeTitle,
    sectionThreeText,
  } = data.whoWeArePage.data.attributes;

  const sectionOne = {
    pageTitle,
    sectionOneTitle,
    sectionOneText,
    sectionOneImage,
    sectionOneImageAlt,
  };

  const sectionTwo = {
    sectionTwoTitle,
    sectionTwoHeader,
    sectionTwoSubHeader,
    sectionTwoSteps,
  };

  const sectionThree = { sectionThreeTitle, sectionThreeText };

  return (
    <main className="who-we-are ">
      <section className="who-we-are__we-block">
        <div className="who-we-are__we-block-inner page-wrapper page-wrapper__padding">
          <div className="">
            <h1 className="heading-1 txt-decor-letter-1 who-we-are__heading">
              {sectionOne.pageTitle}
            </h1>
            <h2 className="heading-2 txt-decor-letter-1 who-we-are__sub-heading">
              {sectionOne.sectionOneTitle}
            </h2>
            <MarkdownText
              className="who-we-are__story-txt"
              content={sectionOne.sectionOneText}
            />
            <img
              src={DotsTop}
              alt="Bacground decoration"
              className="who-we-are__dots-top"
            />
          </div>
        </div>
        <div className="who-we-are__image-box ">
          <div
            className="who-we-are__image"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_CMS_MEDIA}${sectionOne.sectionOneImage.data.attributes.url})`,
            }}
          ></div>
          <p className="who-we-are__image-text page-wrapper__padding">
            {sectionOne.sectionOneImageAlt}
          </p>
          <img
            src={DotsBottom}
            alt="Bacground decoration"
            className="who-we-are__dots-bottom"
          />
        </div>
      </section>

      <section className="who-we-are__vision-block">
        <div className="who-we-are__vision-block-inner page-wrapper page-wrapper__padding">
          <OurVision dataArr={sectionTwo} />
        </div>
      </section>

      <section className="who-we-are__team-block">
        <div className="who-we-are__team-block-inner page-wrapper page-wrapper__padding">
          <h2 className="heading-2 txt-decor-letter-1 who-we-are__sub-heading">
            {sectionThree.sectionThreeTitle}
          </h2>
          <MarkdownText
            className="who-we-are__story-txt"
            content={sectionThree.sectionThreeText}
          />
        </div>
      </section>
    </main>
  );
};

export default WhoWeAre;
