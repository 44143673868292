import "./page-wrapper.styles.scss";

const PageWrapper = ({ children }) => {
  return (
    <main className="wrapper-component page-wrapper page-wrapper__padding">
      {children}
    </main>
  );
};

export default PageWrapper;
