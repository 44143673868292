import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import IntegrationHub from "../integration-hub/integration-hub.component";

const hubAnimation = {
  hidden: {
    x: "100%",
  },
  visible: {
    x: 0,
    transition: {
      ease: "linear",
      duration: 0.6,
    },
  },
};

const HomeHubInner = ({ hubData }) => {
  const navigate = useNavigate();

  const displayCategories = [
    { ihCategory: "Casino" },
    { ihCategory: "Payments" },
    { ihCategory: "Services" },
  ];

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.01 }}
      className="home-hub__inner-box"
    >
      <motion.div className="home-hub__inner" variants={hubAnimation}>
        <div className="home-hub__header-box">
          <h2 className="heading-2 txt-decor-letter-1 ">Integration Hub</h2>
          <p className="home-hub__head-text">
          Our smart iGaming platform is pre-integrated with all the leading providers of content and services, making them available to our clients with a simple configuration process. Explore our portfolio below to find your favourite suppliers.
          </p>
        </div>

        <IntegrationHub categories={displayCategories} integrations={hubData} />
        <button
          className="btn btn-big btn--primary home-hub__button"
          data-btn="View more categories"
          onClick={() => navigate("/integration-hub")}
        ></button>
      </motion.div>
    </motion.div>
  );
};

export default HomeHubInner;
