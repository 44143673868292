import { gql, useQuery } from "@apollo/client";

import MarkdownText from "../../components/markdown-text/markdown-text.component";
import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";

import "./privacy-policy.styles.scss";

const PRIVACY = gql`
  query GetPrivacyPolicy {
    privacyPolicy {
      data {
        attributes {
          Page
        }
      }
    }
  }
`;

const PrivacyPolicy = () => {
  const { loading, error, data } = useQuery(PRIVACY);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const pageText = data.privacyPolicy.data.attributes.Page;

  return (
    <main className="page-privacy page-wrapper page-wrapper__padding">
      <MarkdownText content={pageText} />
    </main>
  );
};

export default PrivacyPolicy;
