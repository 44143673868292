import { useState, useEffect } from "react";

import FormInput from "../form-input/form-input.component";
import WhitepaperFormConfirmation from "../whitepaper-form-confirmation/whitepaper-form-confirmation.component";
import MarkdownText from "../markdown-text/markdown-text.component";

import { submitForm } from "../../utils/submit-form";

import downloadBtn from "../../img/whitepaper/download-btn.png";

import "./whitepaper-form.styles.scss";

const WhitepaperForm = (props) => {
  const [formSubmissionStatus, setFormSubmissionStatus] = useState("");
  const [submissionError, setSubmisionError] = useState(null);
  const [displayPopUp, setDisplayPopUp] = useState(false);

  useEffect(() => {
    if (
      formSubmissionStatus === "Success" ||
      formSubmissionStatus === "Error"
    ) {
      setDisplayPopUp(true);
    }

    return () => setDisplayPopUp(false);
  }, [formSubmissionStatus]);

  const closePopUpHandler = () => {
    setSubmisionError(null);
    setFormSubmissionStatus("");
  };

  const { formStyle, formName, formId, formData } = props;

  return (
    <>
      <form
        className={`${formStyle}`}
        name={formName}
        id={formId}
        onSubmit={(e) =>
          submitForm(e, setFormSubmissionStatus, setSubmisionError)
        }
      >
        {formData.fields .map(
          ({
            label,
            inputID,
            placeholder,
            type,
            name,
            isTextArea,
            isRequired,
            style,
            id,
          }) => (
            <FormInput
              key={id}
              label={label}
              id={inputID}
              type={type}
              placeholder={placeholder}
              name={name}
              required={isRequired ? "required" : "optional"}
              className={style}
              isTextArea={isTextArea}
            />
          )
        )}

        {formData.showConsent && (
          <div className="whitepaper-form__checkbox">
            <label htmlFor="checkbox">
              <MarkdownText content={formData.consent} />
            </label>
            <input id="checkbox" type="checkbox" name="checkbox" required />
          </div>
        )}
        {formData.showContactChoice && (
          <div className="whitepaper-form__checkbox">
            <label htmlFor="checkbox">
              <MarkdownText content={formData.contactChoice} />
            </label>
            <input id="ContactChoice" type="checkbox" name="ContactChoice" value="YES" />
          </div>
        )}

        <button
          className="btn btn-big whitepaper-form__btn"
          type="submit"
          data-btn={formData.btn}
        ></button>
      </form>
      <div style={{ display: `${displayPopUp ? "block" : "none"}` }}>
        <WhitepaperFormConfirmation
          status={formSubmissionStatus}
          errMsg={submissionError}
          closeMsgHandler={closePopUpHandler}
          pdf={formData.whitePaperPdfUrl}
        />
      </div>
    </>
  );
};

export default WhitepaperForm;
