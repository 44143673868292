import Icon from "../../img/icons/ps-404.png";

import "./error-page.styles.scss";

const ErrorPage = () => {
  return (
    <main className="page-error">
      <div className="page-error__inner">
        <img src={Icon} alt="Error" className="page-error__img" />
        <h1 className="heading-1 txt-decor-letter-1">Data loading error</h1>
        <p className="page-error__text">
          An error happened while loading all the page components. Please check
          your connection and refresh the page.
        </p>
      </div>
    </main>
  );
};

export default ErrorPage;
