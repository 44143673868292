import MarkdownText from "../markdown-text/markdown-text.component";

import "./footer-nominations.styles.scss";

const Nominations = ({ nominationsData }) => {
  return (
    <div className="nominations">
      <p className="nominations__title">Latest Awards</p>
      <ul className="nominations__list">
        {nominationsData.map(
          ({ id, text, icon, iconAlt, onDisplayOrder, width: iconWidth }) => (
            <li className="nominations__item" key={id}>
              <img
                src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
                alt={iconAlt}
                className={`nominations__icon nominations__icon-${onDisplayOrder}`}
                style={{ width: iconWidth ? iconWidth : "auto" }}
              />
              <MarkdownText
                className="nominations__description"
                content={text}></MarkdownText>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default Nominations;
