import "./form-input.styles.scss";

const FormInput = ({
  className,
  label,
  displayLabel,
  id,
  isTextArea,
  ...props
}) => {
  return (
    <>
      {isTextArea ? (
        <textarea className={`form-input__text ${className}`} {...props} />
      ) : (
        <input className={`form-input__text ${className}`} {...props} />
      )}
      {displayLabel && <label htmlFor={id}>{label}</label>}
    </>
  );
};

export default FormInput;
