import "./card-integration.styles.scss";

const CardIntegration = ({ data, className }) => {
  const openPopUp = (e) => {
    const cardHash =
      e.target.localName === "img"
        ? e.target.parentElement.hash
        : e.target.hash;
    document.querySelector(cardHash).classList.remove("hub-popUp__hidden");
  };

  return (
    <>
      {data.map(({ id, attributes }) => (
        <div className={`${className}`} key={id}>
          <a
            href={`#${attributes.Title.replaceAll(" ", "-")}`}
            className="card-int"
            onClick={openPopUp}
          >
            <img
              src={`${process.env.REACT_APP_CMS_MEDIA}${attributes.logo.data.attributes.url}`}
              alt={attributes.Title}
              className="card-int__logo"
            />
          </a>
        </div>
      ))}
    </>
  );
};

export default CardIntegration;
