import { useNavigate } from "react-router-dom";

import GitDecoration from "../../img/background-decor/home/dark-vector-right.png";
import GitIcon from "../../img/background-decor/home/git-icon.png";

import "./home-getInTouch.styles.scss";

const HomeGetInTouch = ({ headers }) => {
  const navigate = useNavigate();

  return (
    <section className="section-git page-wrapper">
      <div className="section-git__details">
        <h2 className="heading-2 txt-decor-letter-1">{headers.header}</h2>
        <p className="section-git__text">{headers.sub}</p>
        <div className="section-git__buttons">
          <button
            className="btn btn-big btn--primary"
            data-btn="Book a demo"
            onClick={() => navigate("/request-demo")}
          ></button>
          <button
            className="btn btn-big btn--primary"
            data-btn="Contact us"
            onClick={() => navigate("/contact-us")}
          ></button>
        </div>
      </div>
      <div className="section-git__decor-box">
        <img
          src={GitDecoration}
          alt="Gradient triangle with speechbuble icon"
          className="section-git__image"
        />
        <img
          src={GitIcon}
          alt="Gradient triangle with speechbuble icon"
          className="section-git__icon"
        />
      </div>
    </section>
  );
};

export default HomeGetInTouch;
