import { useEffect } from 'react';
import download from "../../img/top-bar/download.svg";
import close from "../../img/top-bar/close.svg";

import "./top-bar.styles.scss";

const TopBar = () => {
  const hideTopBar = () => {
    const root = document.getElementById('root')
    const bar = document.getElementById('top-bar')
    root.classList.remove('has-top-bar')
    bar.classList.add('hidden')
  }

  useEffect(() => {
    const root = document.getElementById('root')
    root.classList.add('has-top-bar')

    return () => {
      root.classList.remove('has-top-bar')
    }
  }, [])

  return (
    <div className="top-bar" id="top-bar">
      <div className="top-bar__content">
        <div className="top-bar__icon">
        <a href="/how-to-choose-best-igaming-platform-strategy"><img src={download} alt="download" /></a>
        </div>
        <div className="top-bar__text">
        <a href="/how-to-choose-best-igaming-platform-strategy"><p><strong>Read</strong> the Definitive Guide to Platform Selection</p></a>
        </div>
        <div className="top-bar__cta">
          <a href="/how-to-choose-best-igaming-platform-strategy">Download White Paper</a>
        </div>
        <div className="top-bar__close">
          <img src={close} onClick={hideTopBar} alt="close" />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
