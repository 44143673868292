import { useQuery, gql } from "@apollo/client";

import Form from "../../components/form/form.component";
import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";

import Asset1 from "../../img/background-decor/demo/demo-asset-1.svg";
import Asset2 from "../../img/background-decor/demo/demo-asset-2.svg";

import "./demo.styles.scss";

const DEMO = gql`
  query GetDemoPage {
    demoPage {
      data {
        id
        attributes {
          title
          text
          iconAlt
          icon {
            data {
              id
              attributes {
                url
              }
            }
          }
          contactForm {
            btn
            consent
            showConsent
            input {
              id
              label
              name
              placeholder
              type
              style
              isRequired
              inputID
              isTextArea
            }
          }
        }
      }
    }
  }
`;

const Demo = () => {
  const { loading, error, data } = useQuery(DEMO);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const { title, text, icon, iconAlt, contactForm } =
    data.demoPage.data.attributes;

  return (
    <section className="section-demo page-wrapper page-wrapper__padding">
      <div className="demo-form">
        <div className="demo-form__head-box">
          <div className="demo_form__heading">
            <h1 className="heading-1 margin-medium-bt txt-decor-letter-1">
              {title}
            </h1>
            <p className="demo-form__description">{text}</p>
          </div>
          <div className="demo-form__icon-box">
            <img
              src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
              alt={`${iconAlt}`}
              className="demo-form__icon"
            />
          </div>
        </div>

        <Form
          formData={contactForm}
          formStyle="demo-form__form margin-medium-tp"
          formName="request-demo"
          formId="form-demo-requests"
          formBtnStyle="demo-form__submit"
        />
      </div>
      <img
        src={Asset1}
        alt="background decoration"
        className="section-demo__asset1"
      />
      <img
        src={Asset2}
        alt="background decoration"
        className="section-demo__asset2"
      />
    </section>
  );
};

export default Demo;
