import MarkdownText from "../markdown-text/markdown-text.component";

import "./platform-benefits.styles.scss";

import download from "../../img/top-bar/download.svg";
import DotsTop from "../../img/background-decor/platform/benefits-dots-top.svg";
import DotsBottom from "../../img/background-decor/platform/benefits-dots-bottom.svg";

const PlatformBenefits = ({ blockData }) => {
  const { title, blockOneTitle, blockOneText, blockOneCards } = blockData;

  return (
    <div className="platform-benefits" id="benefits">
      <div className="platform-benefits__inner page-wrapper page-wrapper__padding">
        <div className="platform-benefits__head">
          <h1 className="heading-1 txt-decor-letter-1 platform-benefits__h1">
            {title}
          </h1>
          <a href="/how-to-choose-best-igaming-platform-strategy" className="platform-benefits__download-btn">
            <span><img src={download} /></span>
            <span><strong>Download</strong> the Definite Guide
to Platform Selection</span>
          </a>
          <h2 className="heading-2 txt-decor-letter-1 platform-benefits__header">
            {blockOneTitle}
          </h2>
          <p className="platform-benefits__sub-text">{blockOneText}</p>
        </div>
        <div className="platform-benefits__cards">
          <ul className="platform-benefits__items">
            {blockOneCards.map(({ id, title, description, icon, iconAlt }) => (
              <li className="platform-benefits__item" key={id}>
                <img
                  src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
                  alt={`${iconAlt}`}
                  className="platform-benefits__icon"
                />
                <h3 className="heading-3">{title}</h3>
                <MarkdownText
                  className="platform-benefits__text"
                  content={description}
                ></MarkdownText>
              </li>
            ))}
          </ul>
        </div>
        <img
          src={DotsTop}
          alt="Background decoration "
          className="platform-benefits__dots-top"
        />
        <img
          src={DotsBottom}
          alt="Background decoration "
          className="platform-benefits__dots-bottom"
        />
      </div>
    </div>
  );
};

export default PlatformBenefits;
