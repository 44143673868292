const FbShare = () => {
  return (
    <>
      <div id="fb-root"></div>
      <script
        async
        defer
        crossorigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0"
        nonce="FxrCIFNK"
      ></script>
    </>
  );
};

export default FbShare;
