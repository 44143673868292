import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import "./markdown-text.styles.scss";

const MarkdownText = (props) => {
  const { className, content } = props;

  return (
    <ReactMarkdown
      className={`markdown ${className ? className : ""}`}
      remarkPlugins={[remarkGfm]}
    >
      {content}
    </ReactMarkdown>
  );
};

export default MarkdownText;
