import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import LoadingPage from "../loading-page/loading-page.component";
import NewsSlider from "../news-slider/news-slider.component";

import { ReactComponent as DecorTopRight } from "../../img/background-decor/home/home-news-dots-top.svg";
import { ReactComponent as DecorBottomLeft } from "../../img/background-decor/home/home-news-dots.svg";

import "./home-news.styles.scss";

const BLOGPOSTS = gql`
  query GetTeam {
    blogs(sort: "Publication_Date:desc", pagination: { limit: 100 }) {
      data {
        id
        attributes {
          previewImg {
            data {
              id
              attributes {
                url
              }
            }
          }
          previewImgText
          previewText
          Title
          slug
          Publication_Date
          category {
            data {
              id
              attributes {
                Name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

const HomeNews = ({ headers }) => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(BLOGPOSTS);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        data && (
          <section className="section-home-news ">
            <div className="section-home-news__inner page-wrapper">
              <h2 className="heading-2 txt-decor-letter-1">{headers.header}</h2>
              <NewsSlider dataBlock={data.blogs.data} />
              <button
                className="btn btn-big btn--primary section-home-news__button"
                data-btn="See more of the latest news"
                onClick={() => navigate("/news")}
              ></button>
              <DecorTopRight className="section-home-news__decor1" />
              <DecorBottomLeft className="section-home-news__decor2" />
            </div>
          </section>
        )
      )}
    </>
  );
};

export default HomeNews;
