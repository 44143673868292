import { useState, useEffect, useRef } from "react";
import { gql, useQuery } from "@apollo/client";

import SearchBox from "../../components/search-box/search-box.component";

import IntegrationHub from "../../components/integration-hub/integration-hub.component";
import IntegrationPopUp from "../../components/integration-popup/integration-popup.component";
import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";

import "./integrations-page.styles.scss";

const INTEGRATIONS = gql`
  query GetIntegrationHubData {
    integrationsHubs(pagination: { limit: 500 }, sort: "onDisplayOrder") {
      data {
        id
        attributes {
          logo {
            data {
              id
              attributes {
                url
              }
            }
          }
          Title
          popupDetails
          ExtraDetails
          ExtraDetails_Type
          url
          types {
            data {
              id
              attributes {
                ihType
              }
            }
          }
          video {
            data {
              attributes {
                url
              }
            }
          }
          categories {
            data {
              id
              attributes {
                ihCategory
                onDisplayOrder
              }
            }
          }
        }
      }
    }

    integrationHubCategories(sort: "onDisplayOrder") {
      data {
        id
        attributes {
          ihCategory
          onDisplayOrder
        }
      }
    }
    homePage {
      data {
        id
        attributes {
          integrationHub {
            header
            sub
          }
        }
      }
    }
  }
`;

const IntegrationsPage = () => {
  const { loading, error, data } = useQuery(INTEGRATIONS);
  const [searchField, setSearchField] = useState("");
  const [filteredCards, setFilteredCards] = useState(null);
  const [integrationType, setIntegrationType] = useState("");
  const [types, setTypes] = useState(null);
  const [categories, setCategories] = useState(null);
  const [uniqueCategoriesOrdered, setUniqueCategoriesOrdered] = useState(null);
  const searchRef = useRef();
  const [itemsList, setItemsList] = useState(null);
  const [categoryList, setCategoryList] = useState(null);
  const [pageTitles, setPageTitles] = useState(null);

  useEffect(() => {
    if (itemsList) {
      // Check if Type has been used
      const typeFilteredCards = itemsList.filter((item) => {
        return item.attributes.types.data
          ? item.attributes.types.data.some((type) => {
              return type.attributes.ihType
                .toLocaleLowerCase()
                .includes(
                  integrationType.toLowerCase() !== "all"
                    ? integrationType.toLowerCase()
                    : ""
                );
            })
          : "";
      });

      // Apply searchFiled
      const newFilteredCards = typeFilteredCards.filter((item) => {
        return (
          item.attributes.Title.toLocaleLowerCase().includes(searchField) ||
          item.attributes.ExtraDetails?.toLocaleLowerCase().includes(
            searchField
          )
        );
      });
      setFilteredCards(newFilteredCards);
    }
  }, [itemsList, searchField, integrationType]);

  useEffect(() => {
    if (searchRef.current) searchRef.current.children[1].value = "";
    setSearchField("");
  }, [integrationType]);

  useEffect(() => {
    if (itemsList) {
      let newTypes = [];
      let newCategories = [];
      itemsList.forEach((integration) => {
        integration.attributes.categories.data.forEach((item) => {
          newCategories.push(item.attributes.ihCategory);
        });

        integration.attributes.types.data
          ? integration.attributes.types.data.forEach((type) =>
              newTypes.push(type.attributes.ihType)
            )
          : newTypes.push("");
      });

      const uniqueCategories = newCategories.filter(onlyUnique);
      const uniqueTypes = newTypes.filter(onlyUnique);

      setTypes(uniqueTypes);
      setCategories(uniqueCategories);
    }
  }, [itemsList]);

  useEffect(() => {
    if (categories && categoryList) {
      let uniqueCategoriesWithOrderValue = [];

      categories.map((category) => {
        const entry = categoryList.filter(
          (cat) => cat.attributes.ihCategory === category
        )[0].attributes;
        return uniqueCategoriesWithOrderValue.push(entry);
      });

      const uniqueCategoriesOrdered = uniqueCategoriesWithOrderValue.sort(
        (a, b) => a.onDisplayOrder - b.onDisplayOrder
      );

      setUniqueCategoriesOrdered(uniqueCategoriesOrdered);
    }
  }, [categoryList, categories]);

  // Data loading start
  if (loading) return <LoadingPage />;
  if (error) {
    console.error("Integrations page error found.", error);
    return <ErrorPage />;
  }

  !itemsList && setItemsList(data.integrationsHubs.data);
  !categoryList && setCategoryList(data.integrationHubCategories.data);
  !pageTitles && setPageTitles(data.homePage.data.attributes.integrationHub);

  // Data loading end

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  return (
    <>
      {filteredCards && (
        <main className="integrations-hub">
          <div className="integrations-hub__inner page-wrapper">
            <div className="page-wrapper__padding integrations-hub__top">
              <h2 className="heading-2 txt-decor-letter-1">
                {pageTitles.header}
              </h2>
              <p className="integrations-hub__sub-header">{pageTitles.sub}</p>
              <div
                className="integrations-hub__search-container"
                ref={searchRef}
              >
                <div className="integrations-hub__search-filter-box">
                  <label
                    htmlFor="integration-type"
                    className="integrations-hub__search-filter-label"
                  ></label>
                  <select
                    onChange={(e) => {
                      setIntegrationType(e.target.value);
                    }}
                    name="integration-type"
                    id="integration-type"
                    className="integrations-hub__search-filter"
                  >
                    <option
                      value="all"
                      key={"All"}
                      className="integrations-hub__filter-item"
                    >
                      All
                    </option>
                    {types &&
                      types.map((type) => (
                        <option
                          value={type}
                          key={type}
                          className="integrations-hub__filter-item"
                        >
                          {type}
                        </option>
                      ))}
                  </select>
                </div>
                <SearchBox
                  onSearchHandler={onSearchChange}
                  placeholder="Search..."
                  className="integrations-hub__search-box"
                />
                <div className="integrations-hub__button">
                  <button
                    className="btn btn-big btn--primary btn--search"
                    data-btn="Search"
                  ></button>
                </div>
              </div>
              {searchField.length > 0 && filteredCards.length === 0 ? (
                <div className="ih-result">
                  <p className="ih-result__text">
                    Your search for “<span>{searchField}</span>” did not have
                    any matches.
                  </p>
                  <p className="ih-result__text">Suggestions:</p>
                  <ul>
                    <li>Try different keywords</li>
                    <li>
                      Use the dropdown to filter between integrations or
                      providers
                    </li>
                    <li>Double check your spelling is correct</li>
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </div>

            {!filteredCards || !uniqueCategoriesOrdered ? (
              <></>
            ) : (
              <IntegrationHub
                categories={uniqueCategoriesOrdered}
                integrations={filteredCards}
              />
            )}

            {itemsList ? <IntegrationPopUp data={itemsList} /> : <div></div>}
          </div>
        </main>
      )}
    </>
  );
};

export default IntegrationsPage;
