import { useQuery, gql } from "@apollo/client";

import FooterSitemap from "../footer-sitemap/footer-sitemap.component";
import FooterSocial from "../footer-social/footer-social.component";
import Nominations from "../footer-nominations/nominations.component";
import Legal from "../footer-legal/footer-legal.component";
import LoadingPage from "../loading-page/loading-page.component";
import ErrorPage from "../error-page/error-page.component";
import CookiePopup from "../cookie-popup/cookie-popup.component";

import Decor1 from "../../img/background-decor/footer/Asset-1.svg";
import Decor2 from "../../img/background-decor/footer/Asset-2.svg";
import Diamond from "../../img/background-decor/footer/footer-diamond.png";

import "./footer.styles.scss";

const FOOTER = gql`
  query GetFooterData {
    footer {
      data {
        id
        attributes {
          siteNavigation(sort: "onDisplayOrder") {
            id
            blockName
            links(sort: "sortOrder") {
              id
              page
              url
            }
          }
          nominations(sort: "onDisplayOrder") {
            id
            icon {
              data {
                id
                attributes {
                  url
                  height
                }
              }
            }
            iconAlt
            text
            onDisplayOrder
            width
          }
          legal {
            copyright
            links(sort: "sortOrder") {
              id
              page
              url
            }
            disclaimer
            protection
          }
          logos(sort: "onDisplayOrder") {
            id
            img {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
            url
            width
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const { loading, error, data } = useQuery(FOOTER);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const { siteNavigation, nominations, legal, logos } =
    data.footer.data.attributes;

  return (
    <footer className="footer-container">
      <div className="footer-container__inner page-wrapper page-wrapper__padding">
        <FooterSitemap siteMapData={siteNavigation} />
        <FooterSocial />
        <Nominations nominationsData={nominations} />
        <Legal legalData={legal} logos={logos} />
        <div className="footer-container__decor">
          <img src={Decor2} alt="Background decoration" />
          <img src={Decor1} alt="Background decoration" />
        </div>

        <img
          src={Diamond}
          alt="Bacground intersect"
          className="footer-container__int1"
        />

        <img
          src={Diamond}
          alt="Bacground intersect"
          className="footer-container__int2"
        />
      </div>
      <CookiePopup />
    </footer>
  );
};

export default Footer;
