import { useRef } from "react";

import MarkdownText from "../markdown-text/markdown-text.component";

import "./why-card.styles.scss";


const WhyCard = ({ data }) => {
  const btnSnowMore = useRef(null);
  const btnSnowLess = useRef(null);
  const cardText = useRef(null);

  const { icon, iconAlt, title, text } = data;

  const onShowMoreClick = () => {
    cardText.current.classList.toggle("why-card__hidden");
    btnSnowMore.current.classList.toggle("why-card__hidden");
    btnSnowLess.current.classList.toggle("why-card__hidden");
  };

  return (
    <div className="why-card" onClick={onShowMoreClick}>
      <img
        src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
        alt={iconAlt}
        className="why-card__icon"
      />
      <p className="why-card__title">{title}</p>
      <div className="why-card__text why-card__hidden" ref={cardText}>
        <MarkdownText content={text}></MarkdownText>
      </div>

      <button className="why-card__btn why-card__btn-more" ref={btnSnowMore}>
        Show More
      </button>
      <button
        className="why-card__btn why-card__btn-less why-card__hidden"
        ref={btnSnowLess}
      >
        Show Less
      </button>
    </div>
  );
};

export default WhyCard;
