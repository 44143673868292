import CookieConsent from "react-cookie-consent";

const CookiePopup = () => {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Accept'
      cookieName='myAwesomeCookieName2'
      style={{
        background: '#fff',
        color: '#0d131d',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        alignItems: 'center',
        boxShadow: '0 5px 10px rgba(0,0,0,0.3)',
        padding: '5px 20px',
      }}
      buttonStyle={{
        color: '#fff',
        background: '#ff5e04',
        borderRadius: '5px',
      }}
      buttonClasses='btn btn-small btn--primary'
      expires={90}
    >
      Pragmatic Solutions uses cookies to offer you a better experience and
      analyse site traffic. To find out more see our{' '}
      <a className='a-alt' href='/cookie-policy'>
        Cookie Policy
      </a>
      .
      <br />
      By clicking Accept you agree to our use of cookies.
    </CookieConsent>
  );
};

export default CookiePopup;
