import { useState, useEffect } from "react";

import JobPreview from "../job-preview/job-preview.component";

import "./jobs-search.styles.scss";

const departmentDefaultFilter = "All Departments";
const positionDefaultFilter = "All Positions";
const locationDefaultFilter = "All Locations";

const JobsSearch = ({ jobs }) => {
  const [departments, setDepartments] = useState(null);
  const [positions, setPositions] = useState(null);
  const [locations, setLocations] = useState(null);
  const [filters, setFilters] = useState(null);
  const [department, setDepartment] = useState(departmentDefaultFilter);
  const [position, setPosition] = useState(positionDefaultFilter);
  const [location, setLocation] = useState(locationDefaultFilter);
  const [filteredJobs, setFilteredJobs] = useState(null);

  // Update filteredJobs as per filter selected by user
  useEffect(() => {
    const jobsToFilter = jobs;

    // Apply department filter
    let jobsDepartmentFilterApplied;
    if (department === departmentDefaultFilter) {
      jobsDepartmentFilterApplied = jobsToFilter;
    } else {
      jobsDepartmentFilterApplied = jobsToFilter.filter(
        (job) =>
          job.attributes.job_department.data?.attributes.department ===
          department
      );
    }
    // Apply position filter
    let jobsPositionsFilterApplied;
    if (position === positionDefaultFilter) {
      jobsPositionsFilterApplied = jobsDepartmentFilterApplied;
    } else {
      jobsPositionsFilterApplied = jobsDepartmentFilterApplied.filter(
        (job) =>
          job.attributes.job_position.data?.attributes.position === position
      );
    }

    // Apply location filter
    let jobsLocationFilterApplied;
    if (location === locationDefaultFilter) {
      jobsLocationFilterApplied = jobsPositionsFilterApplied;
    } else {
      jobsLocationFilterApplied = jobsPositionsFilterApplied.filter(
        ({ attributes: { job_locations } }) => {
          const testValues = [];
          job_locations.data.length &&
            job_locations.data.forEach((jobLocation) => {
              jobLocation.attributes.location === location
                ? testValues.push(true)
                : testValues.push(false);
            });
          return testValues.includes(true);
        }
      );
    }

    // Set filtered jobs
    setFilteredJobs(jobsLocationFilterApplied);
  }, [department, location, position, jobs]);

  // Adding all the filters into single arr
  useEffect(() => {
    const combinedFilters = [];
    departments &&
      combinedFilters.push({
        filterName: "department",
        selections: departments,
      });
    positions &&
      combinedFilters.push({ filterName: "position", selections: positions });
    locations &&
      combinedFilters.push({ filterName: "location", selections: locations });

    setFilters(combinedFilters);
  }, [departments, positions, locations]);

  // Getting all the unique Departments to use as Filter
  useEffect(() => {
    const departmentsListed = [];
    jobs.forEach((job) => {
      job.attributes.job_department.data &&
        departmentsListed.push(
          job.attributes.job_department.data.attributes.department
        );
    });
    const departmentsUnique = [
      departmentDefaultFilter,
      ...new Set(departmentsListed),
    ];
    setDepartments(departmentsUnique);
  }, [jobs]);

  // Getting all the unique Positions to use as Filter
  useEffect(() => {
    const positionsListed = [];
    jobs.forEach((job) => {
      job.attributes.job_position.data &&
        positionsListed.push(
          job.attributes.job_position.data.attributes.position
        );
    });
    const positionsUnique = [
      positionDefaultFilter,
      ...new Set(positionsListed),
    ];
    setPositions(positionsUnique);
  }, [jobs]);

  // Getting all the unique Locations to use as Filter
  useEffect(() => {
    const locationsListed = [];
    jobs.forEach((job) => {
      const locations = job.attributes.job_locations.data;
      locations.length &&
        locations.forEach((location) => {
          locationsListed.push(location.attributes.location);
        });
    });
    const locationsUnique = [
      locationDefaultFilter,
      ...new Set(locationsListed),
    ];
    setLocations(locationsUnique);
  }, [jobs]);

  // Getting filter value from each drop down
  const onFilterChangeHandler = (event) => {
    if (event.target.name === "location") setLocation(event.target.value);
    if (event.target.name === "position") setPosition(event.target.value);
    if (event.target.name === "department") setDepartment(event.target.value);
  };

  return (
    <div className="job-search">
      <div className="job-search__filter-wrapper">
        <ul className="job-search__filters">
          {filters &&
            filters.map(({ filterName, selections }) => (
              <li className="job-search__filter-box" key={filterName}>
                <select
                  onChange={onFilterChangeHandler}
                  name={filterName}
                  id={filterName}
                  className="job-search__filter"
                >
                  {selections.map((selection, i) => (
                    <option
                      value={selection}
                      key={i}
                      className="job-search__filter-item"
                    >
                      {selection}
                    </option>
                  ))}
                </select>
              </li>
            ))}
        </ul>
      </div>

      {filteredJobs && (
        <div className="job-list">
          <ul className="job-list__jobs">
            {filteredJobs.length ? (
              filteredJobs.map((job) => <JobPreview key={job.id} job={job} />)
            ) : (
              <div className="job-result">
                <p className="job-result__text">
                  Your search for “
                  <span>{`${department} / ${position} / ${location}`}</span>”
                  did not have any matches.
                </p>
                <p className="job-result__text">Suggestions:</p>
                <ul>
                  <li>
                    Use the dropdown menus to filter with different conditions.
                  </li>
                </ul>
              </div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default JobsSearch;
