import "./platform-guide.styles.scss";

import HeaderImg from "../../img/whitepaper/whitepaper.png";
import Asset0 from "../../img/whitepaper/whitepaper-heading-bg-right.svg";

const PlatformGuide = ({ blockData, download }) => {
  const { title, subtitle } = blockData;

  const link = download ? download : '#download'

  return (
    <section className="whitepaper-heading">
      <div className="whitepaper-heading__inner page-wrapper page-wrapper__padding">
        <h1 className="heading-1">{title}</h1>
        <p className="whitepaper-heading__text">{subtitle}</p>
        <a className="whitepaper-heading__cta" href={link}>Download White Paper</a>
        <img src={HeaderImg} alt="Web page with speech bubble" className="whitepaper-heading__img" />
        <img
          src={Asset0}
          alt="background decoration"
          className="whitepaper-heading__asset0"
        />
      </div>
    </section>
  );
};

export default PlatformGuide;
