import { gql, useQuery } from "@apollo/client";

import LoadingPage from "../../components/loading-page/loading-page.component";
import ErrorPage from "../../components/error-page/error-page.component";
import JobsSearch from "../../components/jobs-search/jobs-search.component";
import MarkdownText from "../../components/markdown-text/markdown-text.component";

import "./career.styles.scss";

const CAREERPAGE = gql`
  query GetCareerPageData {
    careerPage {
      data {
        id
        attributes {
          title
          teaser
          textBlock {
            id
            title
            text
          }
          jobsSectionTitle
          jobsSectionTeaser
          blockTitle
          blockTeaser
          blockItems {
            id
            text
            icon {
              data {
                id
                attributes {
                  caption
                  url
                }
              }
            }
          }
        }
      }
    }
    jobPosts(sort: "publicationDate:DESC") {
      data {
        id
        attributes {
          title
          description
          publicationDate
          urlPath
          job_position {
            data {
              id
              attributes {
                position
              }
            }
          }
          job_locations {
            data {
              id
              attributes {
                location
              }
            }
          }
          job_department {
            data {
              id
              attributes {
                department
              }
            }
          }
        }
      }
    }
  }
`;

const Career = () => {
  const { loading, error, data } = useQuery(CAREERPAGE);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const {
    title,
    teaser,
    textBlock,
    jobsSectionTitle,
    jobsSectionTeaser,
    blockTitle,
    blockItems,
    blockTeaser,
  } = data.careerPage.data.attributes;
  const jobs = data.jobPosts.data;

  return (
    <main className="careers">
      <section className="careers__section">
        <div className="careers__section-inner page-wrapper page-wrapper__padding">
          <h1 className="heading-1 txt-decor-letter-1">{title}</h1>

          <MarkdownText
            className="careers__teaser"
            content={teaser}
          ></MarkdownText>
          {textBlock && (
            <ul className="careers__columns">
              {textBlock.map(({ id, title, text }) => (
                <li className="careers__column" key={id}>
                  <h4 className="heading-4">{title}</h4>
                  <MarkdownText
                    className="careers__column-text"
                    content={text}
                  ></MarkdownText>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>

      <section className="careers__section">
        <div className="careers__section-inner page-wrapper page-wrapper__padding">
          <h2 className="heading-1 txt-decor-letter-1">{jobsSectionTitle}</h2>
          {jobs.length ? (
            <MarkdownText
              className="careers__teaser"
              content={jobsSectionTeaser}
            ></MarkdownText>
          ) : (
            <p className="careers__teaser">
              There is no open roles at the moment, please come back in some
              time!
            </p>
          )}
        </div>
        <div className="careers__job-search-container page-wrapper page-wrapper__padding">
          {jobs.length && <JobsSearch jobs={jobs} />}
        </div>
      </section>
      <section className="careers__section">
        <div className="careers__section-inner page-wrapper page-wrapper__padding">
          <h2 className="heading-1 txt-decor-letter-1">{blockTitle}</h2>
          <MarkdownText
            className="careers__teaser"
            content={blockTeaser}
          ></MarkdownText>
          {blockItems && (
            <ul className="careers__perks-list">
              {blockItems.map(({ id, icon, text }) => (
                <li className="careers__perks-item" key={id}>
                  <img
                    src={`${process.env.REACT_APP_CMS_MEDIA}${icon.data.attributes.url}`}
                    alt=""
                    className="careers__perks-icon"
                  />
                  <MarkdownText
                    className="careers__perks-text"
                    content={text}
                  ></MarkdownText>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </main>
  );
};

export default Career;
