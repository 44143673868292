import { useState, useEffect } from "react";

import "./loading-page.styles.scss";

const LoadingPage = () => {
  const [delayRender, setDelayRender] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setDelayRender(false), 500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {!delayRender && (
        <div className='loading-page'>
          <div className='lds-grid'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingPage;
