import { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import WhyCard from "../why-card/why-card.component";
import LoadingPage from "../loading-page/loading-page.component";

import "./home-why.styles.scss";

const WHYPRAGMATIC = gql`
  query {
    homeWhies(sort: "onDisplayOrder") {
      data {
        id
        attributes {
          icon {
            data {
              id
              attributes {
                url
              }
            }
          }
          iconAlt
          title
          text
        }
      }
    }
  }
`;

const HomeWhy = ({ headers }) => {
  const { loading, error, data } = useQuery(WHYPRAGMATIC);
  const [whyBlock, setWhyBlock] = useState(null);

  useEffect(() => {
    if (data) {
      setWhyBlock(data.homeWhies.data);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        whyBlock && (
          <section className="home-why" id="why-ps">
            <div className="home-why__inner page-wrapper page-wrapper__padding">
              <h2 className="heading-2 txt-decor-letter-1 home-why__header">
                {headers.header}
              </h2>
              <h3 className="heading-3 home-why__sub">{headers.sub}</h3>

              <div className="home-why__cards">
                <div className="home-why__card-box">
                  {whyBlock.map(({ id, attributes }) => (
                    <WhyCard
                      data={attributes}
                      key={id}
                      className="home-why__card-box"
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>
        )
      )}
    </>
  );
};

export default HomeWhy;
